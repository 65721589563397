import React, { useState, useEffect } from "react";
import { Col, Row } from "reactstrap";
import Book from "./books";
import Users from "./users";
import { getApi } from "../../../service/api";
import Carts1 from "./userCharts";

const Dashboard = () => {
  const [booksData, setBooksData] = useState();
  const [usersData, setUsersData] = useState();
  const [loansData, setLoansData] = useState();
  const [CategoriesData, setCategoriesData] = useState();
  useEffect(async () => {
    try {
      const getBooks = await getApi({ path: "books/count" });
      //   const getLoans = await getApi({ path: "loans/count" });
      const getCategories = await getApi({ path: "categories/count" });
      const getUsers = await getApi({ path: "users/count" });
      console.log(getBooks, getUsers, getCategories);
      setBooksData(getBooks.data.data);
      //   setLoansData(getLoans.data.data);
      setCategoriesData(getCategories.data.data);
      setUsersData(getUsers.data.data);
    } catch (err) {
      console.log(err);
    }
  }, []);
  return (
    <> 
        <Book data={booksData} />
        <Users data={usersData} />

        {/* <Carts1 />
        <Carts1 /> */}
      
    </>
  );
};

export default Dashboard;
