import axios from "axios";
import Cookies from "js-cookie";
import swal from "sweetalert";
import jsCookies from "js-cookie";

const cookieKeys = {
  userAccessToken: "access_token",
  userRefreshToken: "refresh_token",
};

// const baseURL = "http://localhost:4000/api/v1/";
const baseURL = "https://www.library.omidshayan.dev/api/v1/";
const Axios = axios.create({ baseURL });
const testToken = jsCookies.get("token");
export function getJwt() {
  let jwt = Cookies.get(cookieKeys.userAccessToken) || testToken;
  return jwt;
}
Axios.interceptors.request.use(
  (AxiosConfig) => {
    const newConfig = { ...AxiosConfig };
    newConfig.headers.Authorization = `Bearer ${getJwt()}`;
    newConfig.headers["Content-Type"] = "application/json";
    return newConfig;
  },
  (error) => {
    Promise.reject(error);
  }
);
Axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data

    return response;
  },
  function (error) {
    let message = error.response ? error.response.data?.error : "خطای سرور";
    console.log(error);
    // switch (expr) {
    //   case value:
    //     return
    //   default:
    //     return
    // }
    swal({
      title: "خطا",
      text: message,
      icon: "error",
    });
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export default {
  GET: Axios.get,
  POST: Axios.post,
  PUT: Axios.put,
  PATCH: Axios.patch,
  DELETE: Axios.delete,
};
