import {
  Headphones,
  Home,
  BookOpen,
  Clipboard,
  GitMerge,
  Layers,
  Users,
  MessageCircle,
  LogOut,
} from "react-feather";
import {
  BannerBaseName,
  NotificationsBaseName,
  BooksBaseName,
  CategoriesBaseName,
  LoanBaseName,
  SliderBaseName,
  UserBaseName,
  CommentBaseName,
  Articels,
} from "../../constant";

export const MENUITEMS = [
  {
    menutitle: " مدیریت",
    menucontent: "",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}`,
        icon: Home,
        title: "خانه",
        type: "link",
      },

      {
        title: " کتاب",
        icon: BookOpen,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/admin/insert-${BooksBaseName}`,
            title: "ثبت ",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/admin/show-${BooksBaseName}`,
            title: "نمایش ",
            type: "link",
          },
        ],
      },

      {
        title: " امانت",
        icon: Clipboard,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/admin/insert-${LoanBaseName}`,
            title: "ثبت ",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/admin/show-${LoanBaseName}`,
            title: "نمایش ",
            type: "link",
          },
        ],
      },

      {
        title: " دسته بندی ها",
        icon: GitMerge,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/admin/insert-${CategoriesBaseName}`,
            title: "ثبت ",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/admin/show-${CategoriesBaseName}`,
            title: "نمایش ",
            type: "link",
          },
        ],
      },
      {
        title: "مقالات",
        icon: GitMerge,
        type: "sub",
        active: false,
        children: [
          // {
          //   path: `${process.env.PUBLIC_URL}/admin/insert-${Articels}`,
          //   title: "ثبت ",
          //   type: "link",
          // },
          {
            path: `${process.env.PUBLIC_URL}/admin/show-${Articels}`,
            title: "نمایش ",
            type: "link",
          },
        ],
      },

      // {
      //     title: ' مقالات محصلیان', icon: Headphones, type: 'sub',active: false, children: [
      //         { path: `${process.env.PUBLIC_URL}/admin/insert-article`, title: 'ثبت ', type: 'link'},
      //         { path: `${process.env.PUBLIC_URL}/admin/show-article`, title: 'نمایش ', type: 'link'},
      //     ]
      // },

      {
        title: " کامنت ها",
        icon: MessageCircle,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/admin/show-${CommentBaseName}`,
            title: "نمایش ",
            type: "link",
          },
        ],
      },
      {
        title: " کاربران",
        icon: Users,
        type: "sub",
        active: false,
        children: [
          // {
          //   path: `${process.env.PUBLIC_URL}/admin/insert-${UserBaseName}`,
          //   title: "ثبت ",
          //   type: "link",
          // },
          {
            path: `${process.env.PUBLIC_URL}/admin/insert-${UserBaseName}`,
            title: "ثبت کاربر ",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/admin/show-${UserBaseName}`,
            title: "نمایش ",
            type: "link",
          },
        ],
      },

      {
        title: " اسلایدر",
        icon: Layers,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/admin/insert-${SliderBaseName}`,
            title: "ثبت ",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/admin/show-${SliderBaseName}`,
            title: "نمایش ",
            type: "link",
          },
        ],
      },
      {
        title: "بنر",
        icon: Layers,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/admin/insert-${BannerBaseName}`,
            title: "ثبت ",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/admin/show-${BannerBaseName}`,
            title: "نمایش ",
            type: "link",
          },
        ],
      },
      {
        title: "ارسال کتاب",
        icon: Layers,
        type: "sub",
        active: false,
        children: [
          {
            path: `${process.env.PUBLIC_URL}/admin/insert-${NotificationsBaseName}`,
            title: "ثبت",
            type: "link",
          },
        ],
      },

      // {
      //     title: 'صفحات', icon: Headphones, type: 'sub',active: false, children: [
      //         { path: `${process.env.PUBLIC_URL}/admin/about`,icon:Home, title: 'درباره ما', type: 'link' },
      //         { path: `${process.env.PUBLIC_URL}/admin/contact`,icon:Home, title: 'تماس با ما', type: 'link' },
      //     ]
      // },

      // { path: `${process.env.PUBLIC_URL}/admin/comment`,icon:Home, title: 'مدیریت کامنت ها', type: 'link' },

      // { path: `${process.env.PUBLIC_URL}/admin/messages`,icon:Home, title: 'پیام ها', type: 'link' },

      // { path: `${process.env.PUBLIC_URL}/admin/critics`,icon:Home, title: 'انتقادات و پیشنهادات', type: 'link' },

      // {
      //     title: 'تنظیمات', icon: Headphones, type: 'sub',active: false, children: [
      //         { path: `${process.env.PUBLIC_URL}/admin/profile`,icon:Home, title: 'پروفایل', type: 'link' },
      //     ]
      // },
    ],
  },
];
