import React, { useRef } from "react";
import { ComponentToPrint } from "./printUser";
import { useReactToPrint } from "react-to-print";

const UserInfo = (props) => {
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div >
      <ComponentToPrint data={props.data} ref={componentRef} />
      <button className="btn btn-primary" onClick={handlePrint}>{"پرینت"}</button>
    </div>
  );
};

export default UserInfo;
