import React, {
  Fragment,
  useState,
  useCallback,
  useMemo,
  useEffect,
} from "react";
// import Breadcrumb from '../../layout/breadcrumb'
import differenceBy from "lodash/differenceBy";
import Breadcrumb from "../../../layout/breadcrumb/index";
import Jalali from "moment-jalali";
import { UserDomainUrl } from "../../../config/index.json";
import DataTable from "react-data-table-component";
import Select from "react-select";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Label,
  FormGroup,
  Input,
  CardBody,
  Button,
} from "reactstrap";
import Paginate from "../../paginate";
import { getApi } from "../../../service/api";
import { Edit, Trash, Eye } from "react-feather";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

export default function ManageSelections(props) {
  // const [data, setData] = useState(d);
  const [partsOptions, setPartsOptions] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [getAllCategories, setCategoriesOptions] = useState([]);

  const tableColumns = [
    {
      name: "نام",
      selector: "name",
      minWidth: "200px",
    },

    {
      name: "آدرس",
      cell: (row) => {
        return (
          <>
            <span
              className="text-info"
              style={{ cursor: "pointer" }}
              onClick={() => {
                swal({
                  title: `آدرس کتاب`,
                  text: `الماری : ${row.elmari || "نامشخص"} | طبقه : ${
                    row.floor || "نامشخص"
                  }  |    حرف : ${row.erf || "نامشخص"} | شماره : ${
                    row.part || "نامشخص"
                  }  `,
                  buttons: ["بستن"],
                });
              }}
            >
              نمایش
            </span>
          </>
        );
      },
    },
    {
      name: "موجودی فعلی",
      selector: "nowInventoryBooks",
      minWidth: "150px",
    },
    {
      name: "تعداد کل کتاب",
      selector: "totalBook",
      minWidth: "150px",
    },
    {
      name: "بازدید",
      selector: "review",
    },
    // {
    //   name: "دسته بندی",
    //   selector: (row)=>row.categories[0],
    // },
    {
      name: "نوع",
      selector: (row) => {
        return (
          <>
            {row.type === "read" && "خواندنی"}
            {row.type === "audio" && "صوتی"}
            {row.type === "both" && "هردو"}
            {!row.type && "نامشخص"}
          </>
        );
      },
    },
    {
      name: "تاریخ ثبت",
      selector: (row) => {
        return Jalali(row.createdAt, "YYYY-M-D HH:mm:ss").format("jYYYY/jM/jD");
      },
    },
    {
      name: "وضعیت",
      selector: (row) => {
        return (
          <>
            {row.status === "active" && (
              <span className="text-success">فعال</span>
            )}
            {row.status === "inactive" && (
              <span className="text-danger">غیرفعال</span>
            )}
            {row.status === "pending" && <span>نامشخص</span>}
          </>
        );
      },
    },
    {
      name: "تغییر وضعیت",
      allowOverflow: true,
      button: true,
      cell: (row) => {
        return (
          <div
            onClick={() =>
              props.handleEditOneFeild({
                path: `${props.baseName}/${row._id}`,
                body: {
                  status: row.status === "active" ? "inactive" : "active",
                },
              })
            }
          >
            {row.status === "inactive" || row.status === "pending" ? (
              <button className="btn btn-primary p-1" raised primary>
                فعال شود
              </button>
            ) : (
              <button className="btn btn-primary p-1" raised primary>
                غیرفعال شود
              </button>
            )}
          </div>
        );
      },
    },
    {
      name: "ویرایش",
      allowOverflow: true,
      cell: (row) => {
        return (
          <Link
            to={{
              pathname: `/admin/edite-${props.baseName}/${row._id}`,
              state: { data: { ...row } },
            }}
          >
            <Edit className="text-info" />
          </Link>
        );
      },
    },
    {
      name: "نمایش",
      allowOverflow: true,
      cell: (row) => {
        return (
          <a
            href={`${UserDomainUrl}/BookPage/${row.slug}?id=${row._id}`}
            target={"_blank"}
          >
            <Eye className="text-info" />
          </a>
        );
      },
    },
    {
      name: "حذف",
      cell: (row) => {
        return (
          <>
            <Trash
              className="text-danger"
              style={{ cursor: "pointer" }}
              onClick={() => {
                swal({
                  title: "آیا مطمئنید؟",
                  text: `شما در حال حذف کتاب  ${row.name} میباشید`,
                  icon: "warning",
                  buttons: ["کنسل شود!", "بله, حذف شود"],
                  dangerMode: true,
                }).then(function (isConfirm) {
                  if (isConfirm) {
                    props.handleRemove({
                      path: `${props.baseName}/${row._id}`,
                    });
                  }
                });
              }}
            />
          </>
        );
      },
    },
  ];

  useEffect(async () => {
    try {
      const data = await getApi({
        path: "/books/parts",
      });
      const parsDataForSelct = data.data?.data.map((item) => {
        return { name: item, value: item };
      });
      setPartsOptions([{ name: "همه", value: "" }, ...parsDataForSelct]);

      const fetchCategories = await getApi({
        path: "/categories/whitOuthPaginate",
      });

      setCategoriesOptions(fetchCategories.data?.data);
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <Fragment>
      <Breadcrumb parent="کتاب" title="نمایش کتاب ها" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>{" نمایش تمامی کتاب ها"}</h5>
              </CardHeader>
              <CardBody>
                <Accordion allowZeroExpanded className="mb-4">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <Button
                          className="bg-primary mb-1 w-100 ml-auto"
                          onClick={() => setShowFilter(!showFilter)}
                        >
                          {showFilter
                            ? "بستن نمایش فیلتر"
                            : " نمایش فیلتر پیشرفته"}
                        </Button>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label htmlFor="exampleFormControlSelect3">
                              نام
                            </Label>
                            <Input
                              name="name"
                              onChange={(e) =>
                                props.handleFilter({
                                  filter: { name: e.target.value, page: 1 },
                                })
                              }
                              className="form-control digits"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label htmlFor="exampleFormControlSelect3">
                              نوع
                            </Label>
                            <Input
                              type="select"
                              name="type"
                              onChange={(e) =>
                                props.handleFilter({
                                  filter: { type: e.target.value, page: 1 },
                                })
                              }
                              className="form-control digits"
                            >
                              <option value={""}>{"همه"}</option>
                              <option value={"read"}>{"خواندنی"}</option>
                              <option value={"audio"}>{"صوتی"}</option>
                              <option value={"both"}>{"هردو"}</option>
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label htmlFor="exampleFormControlSelect3">
                              وضعیت
                            </Label>
                            <Input
                              type="select"
                              name="status"
                              onChange={(e) =>
                                props.handleFilter({
                                  filter: { status: e.target.value, page: 1 },
                                })
                              }
                              className="form-control digits"
                            >
                              <option value={""}>{"همه"}</option>
                              <option value={"active"}>{"فعال"}</option>
                              <option value={"pending"}>{"نامشخص"}</option>
                              <option value={"inactive"}>{"غیرفعال"}</option>
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label htmlFor="exampleFormControlSelect3">
                              دسته بندی
                            </Label>
                            <Input
                              type="select"
                              name="categories"
                              onChange={(e) =>
                                props.handleFilter({
                                  filter: {
                                    categories: e.target.value,
                                    page: 1,
                                  },
                                })
                              }
                              className="form-control digits"
                            >
                              <option value={""}>{"همه"}</option>

                              {getAllCategories?.map((item) => {
                                return (
                                  <option value={item._id}>{item.name}</option>
                                );
                              })}
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label htmlFor="exampleFormControlSelect3">
                              سورت بندی
                            </Label>
                            <Input
                              type="select"
                              name="sort"
                              onChange={(e) => {
                                const generateSortFilter = {
                                  createdAt: "",
                                  downloades: "",
                                  review: "",
                                  inventoryBooks: "",
                                  totalBook: "",
                                  [e.target.value]: -1,
                                };

                                return props.handleFilter({
                                  filter: {
                                    ...generateSortFilter,
                                    page: 1,
                                  },
                                });
                              }}
                              className="form-control digits"
                            >
                              <option value={"createdAt"}>{"جدیدترین"}</option>
                              <option value={"downloades"}>
                                {"بیشترین دانلود"}
                              </option>
                              <option value={"inventoryBooks"}>
                                {"بیشترین موجودی فعلی"}
                              </option>
                              <option value={"totalBook"}>
                                {" بیشترین تعداد کل کتاب"}
                              </option>
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                      {/* <Row>
                        <Col>
                          <FormGroup>
                            <Label htmlFor="exampleFormControlInput1">
                              شماره
                            </Label>
                            <Select
                              options={partsOptions}
                              name="parts"
                              placeholder={"جستجوی شماره"}
                              getOptionLabel={(option) => (
                                <span style={{ color: "black" }}>
                                  {" "}
                                  {option.name}
                                </span>
                              )}
                              getOptionValue={(option) => option.value}
                              onChange={(e) =>
                                props.handleFilter({
                                  filter: { part: e.value, page: 1 },
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row> */}
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
                <DataTable
                  data={props.data.docs}
                  columns={tableColumns}
                  noDataComponent="موردی یافت نشد"
                  striped={true}
                  center={true}
                  persistTableHead
                />
                <Paginate
                  pages={props.data.pages}
                  page={props.data.page}
                  total={props.data.total}
                  changePage={(e) =>
                    props.getData({
                      params: { page: e },
                    })
                  }
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}
