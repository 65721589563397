import React from "react";
import CategoryForm from "../../components/admin/grouping/insert-group";
import LoanForm from "../../components/admin/loan/form";
import BookForm from "../../components/admin/books/insert-book";
import SliderForm from "../../components/admin/sliders/form";
import BannerForm from "../../components/admin/banners/form";
import NotificationsForm from "../../components/admin/notification/insert-notifications";
import swal from "sweetalert";
import { postApi } from "../../service/api";
import { useHistory } from "react-router-dom";
import RegisterForm from "../../components/auth/register";

const Index = ({ baseName }) => {
  const router = useHistory();
  const postData = async ({ path, body, redirectPath, notRedirect }) => {
    try {
      await postApi({ path, body });
      console.log(redirectPath);
      const pathFroPushUrl = redirectPath || `/admin/show-${baseName}`;
      //notRedirect  = (reddirect : false)
      !notRedirect && router.push(pathFroPushUrl);

      swal({
        title: "موفق",
        // text: "You clicked the button!",
        icon: "success",
      });
    } catch (err) {
      console.log(err);
    }
  };

  //books
  console.log(baseName)
  const propses = {
    baseName,
    postData,
  };
  const component = {
    categories: <CategoryForm {...propses} />,
    loans: <LoanForm {...propses} />,
    sliders: <SliderForm {...propses} />,
    books: <BookForm {...propses} />,
    banners: <BannerForm {...propses} />,
    auth: <RegisterForm  {...propses}/>,
    notifications: <NotificationsForm {...propses} />,
  };
  
  return <>{component[baseName]} </>;
};

export default Index;
