import React, { Fragment, useState, useCallback, useMemo } from "react";
// import Breadcrumb from '../../layout/breadcrumb'
import differenceBy from "lodash/differenceBy";
import Breadcrumb from "../../../layout/breadcrumb/index";
import { toast } from "react-toastify";
import { statusTypes } from "../../../constant/index";
import DataTable from "react-data-table-component";
import Jalali from "moment-jalali";
// import {tableData} from '../../data/dummyTableData'
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Label,
  FormGroup,
  Input,
  CardBody,
  Button,
} from "reactstrap";
import Select from "react-select";
import { getApi } from "../../../service/api";
import Paginate from "../../paginate";
import Loader from "../../loader";

export default function ManageSelections(props) {
  console.log(props.data);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [userOptions, setUserOptions] = useState([]);

  const handleDeliveryChnage = (row,deliveredDate) => {
    props.handleEditOneFeild({
      path: `${props.baseName}/${row._id}`,
      body: { delivery: !row.delivery, deliveredDate },
    });
  };
  const onchangeInputUser = async (e) => {
    const { data } = await getApi({
      path: "users/user",
      params: {
        mobileOrstudentId: e,
      },
    });
    setUserOptions([{ name: "همه", mobile: "" }, ...data.data.docs]);
  };

  const tableColumns = [
    {
      name: "نام کتاب",
      selector: (row) => {
        return <span>{row.book?.name || "نامشخص"}</span>;
      },
      minWidth: "250px",
    },
    {
      name: "نام کاربر",
      selector: "user.name",
    },
    {
      name: "نوع کاربر",
      selector: (row) => {
        return (
          <>
            {row.user.role === "user" ? "کاربر" : null}
            {row.user.role === "student" ? "محصل" : null}
            {row.user.role === "teacher" ? "استاد" : null}
            {row.user.role === "admin" ? "ادمین" : null}
            {row.user.role === "employee" ? "کارمند" : null}
          </>
        );
      },
    },
    {
      name: "موبایل کاربر",
      selector: "user.mobile",
      minWidth: "150px",
    },

    {
      name: "تاریخ ثبت",
      selector: (row) => {
        return Jalali(row.createdAt, "YYYY-M-D HH:mm:ss").format("jYYYY/jM/jD");
      },
    },
    {
      name: "سررسید تحویل",
      minWidth: "150px",
      selector: (row) => {
        return Jalali(row.deliveryDate, "YYYY-M-D HH:mm:ss").format(
          "jYYYY/jM/jD"
        );
      },
    },
    {
      name: "تاریخ تحویل",
      minWidth: "150px",
      selector: (row) => {
        return row.deliveredDate
          ? Jalali(row.deliveredDate, "YYYY-M-D HH:mm:ss").format("jYYYY/jM/jD")
          : "";
      },
    },
    {
      name: "وضعیت",
      minWidth: "150px",
      selector: (row) => {
        return row.delivery ? (
          <span style={{ color: "green" }}> تحویل داده شد</span>
        ) : (
          <span style={{ color: "red" }}>در انتظار تحویل</span>
        );
      },
    },
    {
      name: "تغییر وضعیت",
      button: true,
      selector: (row) => {
        return row.delivery ? (
          <button
            className="btn btn-info p-1"
            raised
            primary
            onClick={() => handleDeliveryChnage(row,null)}
          >
            درانتظار تحویل
          </button>
        ) : (
          <button
            className="btn btn-success p-1"
            raised
            primary
            onClick={() => handleDeliveryChnage(row,new Date())}
          >
            تحویل داده شده
          </button>
        );
      },
    },
  ];

  const handleRowSelected = React.useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  return (
    <Fragment>
      <Breadcrumb parent="امانت" title=" امانت ها" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>{"نمایش تمامی امانت ها"}</h5>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor="exampleFormControlSelect3">وضعیت</Label>
                      <Input
                        type="select"
                        name="lang"
                        onChange={(e) =>
                          props.handleFilter({
                            filter: { delivery: e.target.value, page: 1 },
                          })
                        }
                        className="form-control digits"
                      >
                        <option value={""}>{"همه"}</option>
                        <option value={false}>{"درانتظار تحویل"}</option>
                        <option value={true}>{"تحویل داده شده"}</option>
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor="exampleFormControlInput1">
                        شماره تماس امانت گیرنده یا آی دی محصل
                      </Label>
                      <Select
                        options={userOptions}
                        name="book"
                        onInputChange={(e) => {
                          onchangeInputUser(e);
                        }}
                        placeholder={"جستجو براساس موبایل یا آی دی محصل"}
                        getOptionLabel={(option) => (
                          <span style={{ color: "black" }}>
                            {" "}
                            {option.name +
                              (option.mobile ? " - " : "") +
                              option.mobile}
                          </span>
                        )}
                        getOptionValue={(option) => option._id}
                        onChange={(e) =>
                          props.handleFilter({
                            filter: { user: e._id, page: 1 },
                          })
                        }
                        filterOption={false}
                        // value={this.props.value}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <DataTable
                  data={props.data.docs}
                  columns={tableColumns}
                  noDataComponent="موردی یافت نشد"
                  striped={true}
                  center={true}
                  persistTableHead
                />
                <Paginate
                  pages={props.data.pages}
                  page={props.data.page}
                  total={props.data.total}
                  changePage={(e) =>
                    props.getData({
                      params: { page: e },
                    })
                  }
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}
