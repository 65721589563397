import React, { Fragment, useState } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Button,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Select from "react-select";
import { getApi } from "../../../service/api";
import swal from "sweetalert";
import { userRole } from "../../../constant";
import BtnLoader from "../../loader/btnLoader";

const BaseInput = (props) => {
  const [bookOptions, setBookOptions] = useState([]);
  const [book, setBook] = useState({});
  const [isSubmitBtnLoader, setIsSubmitBtnLoader] = useState(false);
  const [role, setRole] = useState([]);
  const handleSubmit = async (values) => {
    setIsSubmitBtnLoader(true);
    const messageNotificationBaseName = book?._id ? "books" : "public";

    const requestInfo = {
      path: props.baseName + "/" + "public",
      body: {
        ...values,
        book: {
          name: book.name,
          images: book.images,
          id: book?._id,
        },
        role: role.map((item) => item.value),
        baseName: messageNotificationBaseName,
      },
      notRedirect: true,
    };

    await props.postData(requestInfo);
    setIsSubmitBtnLoader(false);
  };
  const onchangeInputBook = async (e) => {
    console.log(e);
    const { data } = await getApi({
      path: "books",
      params: {
        name: e,
      },
    });
    setBookOptions(data.data.docs);
  };
  return (
    <Fragment>
      <Formik
        initialValues={{
          message: "",
        }}
        validationSchema={Yup.object().shape({
          message: Yup.string().required(
            <span style={{ color: "red", padding: "2px" }}>متن الزامیست</span>
          ),
        })}
        onSubmit={handleSubmit}
      >
        <Form className="needs-validation">
          <Breadcrumb parent="ارسال کتاب" title="ارسال کتاب" />
          <Container fluid={true}>
            <Row>
              <Col sm="12">
                <Card>
                  <CardHeader>
                    <h5>ارسال کتاب</h5>
                  </CardHeader>
                  {/* <Form className="form theme-form" onSubmit={()=>}> */}
                  <CardBody>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label htmlFor="exampleFormControlInput1">
                            متن پیام
                          </Label>
                          <Field
                            className="form-control"
                            name="message"
                            type="text"
                            // innerRef={register({ required: true })}
                          />
                          <ErrorMessage name="message" />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label htmlFor="exampleFormControlInput1">
                            نوع کاربر
                          </Label>
                          <Select
                            options={Object.keys(userRole).map(
                              (item) => userRole[item]
                            )}
                            name="books"
                            onInputChange={(e) => {
                              onchangeInputBook(e);
                            }}
                            placeholder="به کدام نوع کاربر ارسال شود"
                            getOptionLabel={(option) =>
                              option.name && (
                                <span style={{ color: "black" }}>
                                  {" "}
                                  {option.name}
                                </span>
                              )
                            }
                            getOptionValue={(option) => option.value}
                            onChange={(e) => {
                              setRole(e);
                            }}
                            isMulti
                            filterOption={false}
                            // value={this.props.value}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label htmlFor="exampleFormControlInput1">لینک</Label>
                          <Select
                            options={bookOptions}
                            placeholder="نام کتاب را جستجو کنید"
                            name="book"
                            onInputChange={(e) => {
                              onchangeInputBook(e);
                            }}
                            getOptionLabel={(option) =>
                              option.name && (
                                <span style={{ color: "black" }}>
                                  {" "}
                                  {option.name + " - " + option.author}
                                </span>
                              )
                            }
                            getOptionValue={(option) => option._id}
                            onChange={(e) => setBook(e)}
                            filterOption={false}
                            value={book}
                          />
                          <ErrorMessage name="book" />
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <BtnLoader show={isSubmitBtnLoader} />
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Container>
        </Form>
      </Formik>
    </Fragment>
  );
};

export default BaseInput;
