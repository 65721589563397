import http from "./index";

export const getApi = async ({ params, path }) => {
  return await http.GET(`${path}`, { params });
};

export const postApi = async ({ path, body }) => {
  return http.POST(`${path}`, body);
};
export const putApi = async ({ path, body }) => {
  console.log("poutApi  : ", path, body);

  return await http.PUT(`${path}`, body);
};
export const patchApi = async ({ path, body }) => {
  console.log("patchApi  : ", path, body);

  return await http.PATCH(`${path}`, body);
};
export const deleteApi = async ({ path, params }) => {
  return await http.DELETE(`${path}`, params);
};
