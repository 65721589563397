import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  Label,
  Button,
  NavItem,
  NavLink,
  Nav,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  TabContent,
  TabPane,
} from "reactstrap";
import Breadcrumb from "../../layout/breadcrumb";
import BtnLoader from "../loader/btnLoader";
import { postApi } from "../../service/api";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";

const Register = (props) => {
  const [isSubmitBtnLoader, setIsSubmitBtnLoader] = useState(false);
  const [togglePassword, setTogglePassword] = useState(false);
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("user");

  const [selected, setSelected] = useState("firebase");

  const handleChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (values) => {
    try {
      const body = {
        ...values,
        role,
        studentId: String(values.studentId),
        mobile: String(values.mobile),
      };

      await props.postData({
        path: "/auth/register",
        body,
        notRedirect: true,
      });
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <Formik
        initialValues={{
          name: "",
          password: "",
          email: "",
          mobile: "",
          studentId: "",
          generateBy: "admin",
          role: "student",
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required(
            <span style={{ color: "red", padding: "2px" }}>
              نام و تخلص الزامیست
            </span>
          ),
          mobile: Yup.string().required(
            <span style={{ color: "red", padding: "2px" }}>
              موبایل الزامیست
            </span>
          ),
          password: Yup.string()
            .min(
              6,
              <span style={{ color: "red", padding: "2px" }}>
                رمز عبور حداقل 6 کاراکتر باید باشد
              </span>
            )
            .required(
              <span style={{ color: "red", padding: "2px" }}>
                رمز عبور الزامیست
              </span>
            ),
          email: Yup.string().email(
            <span style={{ color: "red", padding: "2px" }}>
              فرمت ایمیل اشتباه است
            </span>
          ),
          studentId: Yup.string().required(
            <span style={{ color: "red", padding: "2px" }}>
              آی دی محصل الزامیست
            </span>
          ),
        })}
        onSubmit={handleSubmit}
      >
        <Form className="needs-validation">
          <Breadcrumb parent="کاربر" title="ثبت کاربر" />
          <Container fluid={true}>
            <Row>
              <Col sm="12">
                <Card>
                  <CardHeader>
                    <h5>ثبت کاربر </h5>
                  </CardHeader>
                  {/* <Form className="form theme-form" onSubmit={()=>}> */}
                  <CardBody>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label htmlFor="exampleFormControlInput1">
                            نام و تخلص
                          </Label>
                          <Field
                            className="form-control"
                            name="name"
                            type="text"
                          />
                          <ErrorMessage name="name" />
                        </FormGroup>
                      </Col>
                    </Row>
                    <FormGroup>
                      <Label htmlFor="exampleFormControlInput1">موبایل</Label>
                      <Field
                        className="form-control"
                        name="mobile"
                        type="text"
                      />
                      <ErrorMessage name="mobile" />
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor="exampleFormControlInput1">ایمیل</Label>
                      <Field
                        className="form-control"
                        name="email"
                        type="text"
                      />
                      <ErrorMessage name="email" />
                    </FormGroup>
                    <FormGroup>
                      <Label>نمبر کارت</Label>
                      <Field
                        className="form-control"
                        name="nombrCard"
                        type="text"
                      />
                    </FormGroup>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label htmlFor="exampleFormControlInput1">
                            آی دی محصل
                          </Label>
                          <Field
                            className="form-control"
                            name="studentId"
                            type="number"
                          />
                          <ErrorMessage name="studentId" />
                        </FormGroup>
                      </Col>
                    </Row>
                    <FormGroup>
                      <Label htmlFor="exampleFormControlInput1">دانشکده</Label>
                      <Field
                        className="form-control"
                        name="college"
                        type="text"
                      />
                      <ErrorMessage name="email" />
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor="exampleFormControlSelect3">وظیفه</Label>
                      <Input
                        type="select"
                        name="role"
                        onChange={(e) => {
                          setRole(e.target.value);
                        }}
                        className="form-control digits"
                      >
                        <option value={"user"}>{"کاربر"}</option>
                        <option value={"student"}>{"محصل"}</option>
                        <option value={"employee"}>{"کارمند"}</option>
                        <option value={"teacher"}>{"استاد"}</option>
                      </Input>
                    </FormGroup>

                    <Row>
                      <Col>
                        <FormGroup>
                          <Label htmlFor="exampleFormControlInput1">
                            رمز عبور
                          </Label>
                          <Field
                            className="form-control"
                            name="password"
                            type="text"
                          />
                          <ErrorMessage name="password" />
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <BtnLoader show={isSubmitBtnLoader} />
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Container>
        </Form>
      </Formik>
    </>
  );
};

export default Register;
