import React, { Fragment, useState, useCallback, useMemo, useRef } from "react";
// import Breadcrumb from '../../layout/breadcrumb'
import differenceBy from "lodash/differenceBy";
import Breadcrumb from "../../../layout/breadcrumb/index";
import Jalali from "moment-jalali";
import { statusTypes } from "../../../constant/index";
import DataTable from "react-data-table-component";
// import {tableData} from '../../data/dummyTableData'
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Label,
  FormGroup,
  Input,
} from "reactstrap";
import Paginate from "../../paginate";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { ComponentToPrint } from "./printUser";
import SwalV2 from "sweetalert2";
import SwalV2Content from "sweetalert2-react-content";
import UserInfo from "./userInfo";

export default function ManageSelections(props) {
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const mySwlV2 = SwalV2Content(SwalV2);

  const tableColumns = [
    {
      name: "نام",
      selector: "name",
    },
    {
      name: "شماره تماس",
      selector: "mobile",
    },

    {
      name: "وضعیت",
      selector: (row) => {
        return (
          <>
            {row.status === "active" ? (
              <span className="text-success">فعال</span>
            ) : null}
            {row.status === "inactive" ? (
              <span className="text-danger">غیرفعال</span>
            ) : null}
            {row.status === "pending" ? <span>نامشخص</span> : null}
          </>
        );
      },
    },
    {
      name: "دانشکده",
      selector: "college",
    },
    {
      name: "نمبر کارت",
      selector: "nombrCard",
    },

    {
      name: "نوع کاربر",
      selector: (row) => {
        return (
          <>
            {row.role === "user" ? "کاربر" : null}
            {row.role === "student" ? "محصل" : null}
            {row.role === "teacher" ? "استاد" : null}
            {row.role === "admin" ? "ادمین" : null}
            {row.role === "employee" ? "کارمند" : null}
          </>
        );
      },
    },

    {
      name: "تاریخ ثبت",
      selector: (row) => {
        return Jalali(row.createdAt, "YYYY-M-D HH:mm:ss").format("jYYYY/jM/jD");
      },
    },
    {
      name: "پرینت",
      maxWidth: "100px",
      selector: (row) => {
        return (
          <Button
            className="btn btn-primary p-1"
            onClick={() => {
              mySwlV2.fire({
                html: <UserInfo data={row} />,
                confirmButtonText: "بستن",
              });
            }}
          >
            نمایش
          </Button>
        );
      },
    },
    {
      name: "تغییر وضعیت",
      allowOverflow: true,
      button: true,
      cell: (row) => {
        return (
          <div
            onClick={() =>
              props.handleEditOneFeild({
                path: `${props.baseName}/${row._id}`,
                body: {
                  status: row.status === "active" ? "inactive" : "active",
                },
              })
            }
          >
            {row.status === "inactive" || row.status === "pending" ? (
              <button className="btn btn-primary p-1" raised primary>
                فعال شود
              </button>
            ) : (
              <button className="btn btn-primary p-1" raised primary>
                غیرفعال شود
              </button>
            )}
          </div>
        );
      },
    },
  ];

  const handleRowSelected = React.useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const contextActions = React.useMemo(() => {
    return (
      <button
        key="delete"
        onClick={() => alert()}
        style={{ backgroundColor: "red" }}
        icon
      >
        Delete
      </button>
    );
  }, [props.data?.docs, selectedRows, toggleCleared]);

  return (
    <Fragment>
      <Breadcrumb parent="کاربران" title="نمایش همه" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>{"نمایش تمامی کاربران"}</h5>
              </CardHeader>
              <CardBody>
                <Accordion allowZeroExpanded className="mb-4">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <Button
                          className="bg-primary mb-1 w-100 ml-auto"
                          onClick={() => setShowFilter(!showFilter)}
                        >
                          {showFilter
                            ? "بستن نمایش فیلتر"
                            : " نمایش فیلتر پیشرفته"}
                        </Button>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <Row>
                        <Col sm="12" md="6">
                          <FormGroup>
                            <Label htmlFor="exampleFormControlSelect3">
                              جستجو براساس موبایل
                            </Label>
                            <Input
                              name="mobile"
                              onChange={(e) => {
                                e.target.value > 5 &&
                                  props.handleFilter({
                                    filter: { mobile: e.target.value, page: 1 },
                                  });
                              }}
                              className="form-control digits"
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="12" md="6">
                          <FormGroup>
                            <Label htmlFor="exampleFormControlSelect3">
                              جستجو براساس شماره محصل
                            </Label>
                            <Input
                              name="mobile"
                              onChange={(e) => {
                                e.target.value > 2 &&
                                  props.handleFilter({
                                    filter: {
                                      studentId: e.target.value,
                                      page: 1,
                                    },
                                  });
                              }}
                              className="form-control digits"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label htmlFor="exampleFormControlSelect3">
                              وضعیت
                            </Label>
                            <Input
                              type="select"
                              name="type"
                              onChange={(e) =>
                                props.handleFilter({
                                  filter: { status: e.target.value, page: 1 },
                                })
                              }
                              className="form-control digits"
                            >
                              <option value={""}>{"همه"}</option>
                              <option value={"active"}>{"فعال"}</option>
                              <option value={"inactive"}>{"غیرفعال"}</option>
                              <option value={"pending"}>
                                {"درانتظار تایید"}
                              </option>
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label htmlFor="exampleFormControlSelect3">
                              وظیفه
                            </Label>
                            <Input
                              type="select"
                              name="type"
                              onChange={(e) =>
                                props.handleFilter({
                                  filter: { role: e.target.value, page: 1 },
                                })
                              }
                              className="form-control digits"
                            >
                              <option value={""}>{"همه"}</option>
                              <option value={"admin"}>{"ادمین"}</option>
                              <option value={"teacher"}>{"استاد"}</option>
                              <option value={"user"}>{"کاربر عادی"}</option>
                              <option value={"student"}>{"محصل"}</option>
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
                <DataTable
                  data={props.data?.docs}
                  columns={tableColumns}
                  striped={true}
                  center={true}
                  noDataComponent="موردی یافت نشد"
                  persistTableHead
                  contextActions={contextActions}
                  onSelectedRowsChange={handleRowSelected}
                  clearSelectedRows={toggleCleared}
                />
                <Paginate
                  pages={props.data?.pages}
                  page={props.data?.page}
                  total={props.data?.total}
                  changePage={(e) =>
                    props.getData({
                      params: { page: e },
                    })
                  }
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}
