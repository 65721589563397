import React from "react";
import { Col, Card, Row, CardBody } from "reactstrap";
const Books = (props) => {
  return (
    <>
      <Col md={"6"} className="float-left  mt-4">
        <Card className="bg-info bg-gradienttext-center  text-center">
          <CardBody>
            <div className="media align-items-center">
              <div className="media-body right-chart-content">
                <h4>
                  <span className="new-box">{" تعداد مجموع کتاب ها  :"}</span>
                  {props.data?.allData}
                </h4>
              </div>
              <div className="knob-block">
                <div className="knob1" id="ordervalue1"></div>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col md={"6"} className="float-left mt-4">
        <Card className="bg-info bg-gradient text-center">
          <CardBody>
            <div className="media align-items-center">
              <div className="media-body right-chart-content">
                <h4>
                  <span className="new-box">
                    {"  مجموع کتاب های فعال  :"}
                    {props.data?.allActive}
                  </span>
                </h4>
              </div>
              <div className="knob-block text-center">
                <div className="knob1" id="ordervalue1"></div>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default Books;
