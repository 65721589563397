import React, { Fragment, useState, useEffect } from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./components/app";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import store from "./store";
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  HashRouter,
} from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { routes } from "./route";
import ConfigDB from "./data/customizer/config";
import Dashboard from "./components/admin/dashboard";
import { classes } from "./data/layouts";
import Auth from "../src/components/auth/requireAuth";
import "./myStyle/index.css";
import CreateNewData from "./continers/createNewData";
import ShowAllData from "./continers/showAllData";
import EditData from "./continers/editeData";
import {
  BooksBaseName,
  CategoriesBaseName,
  LoanBaseName,
  CommentBaseName,
  UserBaseName,
  AuthBaseName,
  SliderBaseName,
  NotificationsBaseName,
  BannerBaseName,
  Articels,
} from "./constant/index";


const Root = (props) => {
  const [anim, setAnim] = useState("");

  const animation =
    localStorage.getItem("animation") ||
    ConfigDB.data.router_animation ||
    "fade";
  const abortController = new AbortController();
  const defaultLayoutObj = classes.find(
    (item) => Object.values(item).pop(1) === "compact-wrapper"
  );
  const layout =
    localStorage.getItem("layout") || Object.keys(defaultLayoutObj).pop();

  useEffect(() => {
    setAnim(animation);
    console.ignoredYellowBox = ["Warning: Each", "Warning: Failed"];
    console.disableYellowBox = true;
    return function cleanup() {
      abortController.abort();
    };
    // eslint-disable-next-line
  }, []);
  // alert(props.match.params);

  return (
    <Fragment>
      <Provider store={store}>
        <BrowserRouter>
          <Auth>
            <Switch>
              <HashRouter>
                <App>
                  <Route exact path={"/"} component={Dashboard} />
                  <TransitionGroup>
                    {/* Book---------------------------------------------------------------------- */}
                    <Route
                      path={`/admin/insert-${BooksBaseName}`}
                      render={() => <CreateNewData baseName={BooksBaseName} />}
                    />
                    <Route
                      path={`/admin/show-${BooksBaseName}`}
                      render={() => <ShowAllData baseName={BooksBaseName} />}
                    />
                    <Route
                      path={`/admin/edite-${BooksBaseName}/:id`}
                      render={() => <EditData baseName={BooksBaseName} />}
                    />
                    {/* Banner ---------------------------------------------------------------------- */}
                    <Route
                      path={`/admin/insert-${BannerBaseName}`}
                      render={() => <CreateNewData baseName={BannerBaseName} />}
                    />
                    <Route
                      path={`/admin/show-${BannerBaseName}`}
                      render={() => <ShowAllData baseName={BannerBaseName} />}
                    />
                    <Route
                      path={`/admin/edite-${BannerBaseName}/:id`}
                      render={() => <EditData baseName={BannerBaseName} />}
                    />
                    {/* Categories---------------------------------------------------------------------- */}
                    <Route
                      path={`/admin/insert-${CategoriesBaseName}`}
                      render={() => (
                        <CreateNewData baseName={CategoriesBaseName} />
                      )}
                    />
                    <Route
                      path={`/admin/show-${CategoriesBaseName}`}
                      render={() => (
                        <ShowAllData baseName={CategoriesBaseName} />
                      )}
                    />
                    <Route
                      path={`/admin/edite-${CategoriesBaseName}/:id`}
                      render={() => <EditData baseName={CategoriesBaseName} />}
                    />
                    {/* Loan---------------------------------------------------------------------- */}
                    <Route
                      path={`/admin/insert-${LoanBaseName}`}
                      render={() => <CreateNewData baseName={LoanBaseName} />}
                    />
                    <Route
                      path={`/admin/show-${LoanBaseName}`}
                      render={() => <ShowAllData baseName={LoanBaseName} />}
                    />
                    <Route
                      path={`/admin/edite-${LoanBaseName}/:id`}
                      render={() => <EditData baseName={LoanBaseName} />}
                    />
                    {/* Comments---------------------------------------------------------------------- */}

                    <Route
                      path={`/admin/show-${CommentBaseName}`}
                      render={() => <ShowAllData baseName={CommentBaseName} />}
                    />
                    {/* Articls---------------------------------------------------------------------- */}

                    <Route
                      path={`/admin/show-${Articels}`}
                      render={() => <ShowAllData baseName={Articels} />}
                    />

                    {/* User---------------------------------------------------------------------- */}
                    <Route
                      path={`/admin/insert-${UserBaseName}`}
                      render={() => <CreateNewData baseName={AuthBaseName} />} //basename = auth
                    />
                    <Route
                      path={`/admin/show-${UserBaseName}`}
                      render={() => <ShowAllData baseName={UserBaseName} />}
                    />

                    {/* Slider---------------------------------------------------------------------- */}
                    <Route
                      path={`/admin/insert-${SliderBaseName}`}
                      render={() => <CreateNewData baseName={SliderBaseName} />}
                    />
                    <Route
                      path={`/admin/show-${SliderBaseName}`}
                      render={() => <ShowAllData baseName={SliderBaseName} />}
                    />
                    <Route
                      path={`/admin/edite-${SliderBaseName}/:id`}
                      render={() => <EditData baseName={SliderBaseName} />}
                    />
                    {/* Notification---------------------------------------------------------------------- */}
                    <Route
                      path={`/admin/insert-${NotificationsBaseName}`}
                      render={() => (
                        <CreateNewData baseName={NotificationsBaseName} />
                      )}
                    />
                    {/* Auth ---------------------------------------------------------------------- */}
                    <Route
                      path={`/admin/insert-${AuthBaseName}`}
                      render={() => <CreateNewData baseName={AuthBaseName} />}
                    />
                  </TransitionGroup>
                </App>
              </HashRouter>
            </Switch>
          </Auth>
        </BrowserRouter>
      </Provider>
    </Fragment>
  );
};
Root.initialProps = () => {
  alert();
};
ReactDOM.render(<Root />, document.getElementById("root"));

serviceWorker.unregister();
