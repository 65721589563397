import React, { useState, useEffect } from "react";
import "./preLoader.css";

const Loader = (props) => {
  return (
    <div className={props.true ? "Loader" : "Loader LoaderHide"}>
      <div className="Loader_Box">
        <div className="container">
          <img src={require("../../assets/book.png")} alt="Loding" />
          <p>{"لطفا منتظر بمانید"}</p>
        </div>
      </div>
    </div>
  );
};
export default Loader;
