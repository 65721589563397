import React from "react";
import { Col, Card, Row, CardBody } from "reactstrap";
const Users = (props) => {
  return (
    <> 
      <Col sm={"6"} className="float-left mt-4">
        <Card  className="bg-primary bg-gradient text-center">
          <CardBody>
            <div className="media align-items-center">
              <div className="media-body right-chart-content">
                <h4>
                  <span className="new-box">{" تعداد مجموع کاربران   :"}</span>
                  {props.data?.allData}
                </h4>
              </div>
              <div className="knob-block text-center">
                <div className="knob1" id="ordervalue1"></div>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col sm={"6"} className="float-left mt-4">
        <Card className="bg-primary bg-gradient text-center">
          <CardBody>
            <div className="media align-items-center">
              <div className="media-body right-chart-content">
                <h4>
                  <span className="new-box">
                    {" تعداد  کاربران فعال  :"}
                    {props.data?.allActive}
                  </span>
                </h4>
              </div>
              <div className="knob-block text-center">
                <div className="knob1" id="ordervalue1"></div>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col> 
      {/* <Col md={"6"}>
        <Card>
          <CardBody>
            <div className="media align-items-center">
              <div className="media-body right-chart-content">
                <h4>
                  {"9000"}
                  <span className="new-box">{"  "}</span>
                </h4>
                <span>{"PurchaseOrderValue"}</span>
              </div>
              <div className="knob-block text-center">
                <div className="knob1" id="ordervalue1"></div>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col> */}
    </>
  );
};

export default Users;
