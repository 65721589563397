import React, { Fragment, useState } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Button,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import BtnLoader from "../../loader/btnLoader";
const BaseInput = (props) => {
  const [isSubmitBtnLoader, setIsSubmitBtnLoader] = useState(false);

  const handleSubmit = (values) => {
    setIsSubmitBtnLoader(true);

    const requestInfo = {
      path: props.data ? `${props.baseName}/${props.data._id}` : props.baseName,
      body: {
        ...values,
      },
    };
    if (!props.data) {
      //create
      props.postData(requestInfo);
    } else {
      //edite
      props.putData(requestInfo);
    }
    setIsSubmitBtnLoader(false);
  };
  return (
    <Fragment>
      <Formik
        initialValues={{
          name: props.data?.name || "",
          status: props.data?.status || "pending",
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required(
            <span style={{ color: "red", padding: "2px" }}>نام الزامیست</span>
          ),
        })}
        onSubmit={handleSubmit}
      >
        <Form className="needs-validation">
          <Breadcrumb parent="دسته بندی" title="ثبت دسته بندی" />
          <Container fluid={true}>
            <Row>
              <Col sm="12">
                <Card>
                  <CardHeader>
                    <h5>
                      {props.data
                        ? "ویرایش دسته بندی کتاب"
                        : "ثبت دسته بندی کتاب"}
                    </h5>
                  </CardHeader>
                  {/* <Form className="form theme-form" onSubmit={()=>}> */}
                  <CardBody>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label htmlFor="exampleFormControlInput1">
                            نام دسته بندی کتاب
                          </Label>
                          <Field
                            className="form-control"
                            name="name"
                            type="text"
                            // innerRef={register({ required: true })}
                          />
                          <ErrorMessage name="name" />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <FormGroup>
                          <Label htmlFor="exampleFormControlSelect9">
                            وضعیت
                          </Label>

                          <Field
                            name="status"
                            as="select"
                            className="form-control digits"
                          >
                            <option value="pending">درانتظار تایید</option>
                            <option value="active">فعال</option>
                            <option value="inactive">غیرفعال</option>
                          </Field>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <BtnLoader show={isSubmitBtnLoader} />
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Container>
        </Form>
      </Formik>
    </Fragment>
  );
};

export default BaseInput;
