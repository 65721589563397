import React from "react";
import "./print.css";
export const ComponentToPrint = React.forwardRef((props, ref) => {
  console.log(props.data);
  return (
    <div ref={ref} class="main-card">
      <div className="title-card mt-5">
        <div>ااا</div>
        <div>وزارت تحصیلات عالی</div>
        <div>پوهنتون غالب</div>
        <div>Ghalib University</div>
        <hr className="line-card" />
        <div className="lib-card">کارت کتابخانه</div>
      </div>

      <div style={{
        fontSize:"20px",
        marginTop:"50px"
      }}>
        <div
          className="text-card float-right"
          style={{ textAlign: "right", width: "40%" }}
        >
          {" "}
          نمبر کارت: {props.data.nombrCard}
        </div>
        <div
          className="text-card float-right"
          style={{ textAlign: "right", width: "40%" }}
        >
          نام و تخلص :{props.data.name}
        </div>
        <div
          className="text-card float-right"
          style={{ textAlign: "right", width: "40%" }}
        >
          پوهنحی: {props.data.college}
        </div>
        <div
          className="text-card float-right"
          style={{ textAlign: "right", width: "40%" }}
        >
          آی دی :{props.data.studentId}
        </div>
      </div>
    </div>
  );
});
