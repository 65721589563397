import React, { useEffect, useState } from "react";
import BookTable from "../../components/admin/books/show-books";
import LonsTable from "../../components/admin/loan/show-loans";
import UsersTable from "../../components/admin/users/show-users";
import CategoryTable from "../../components/admin/grouping/show-group";
import BannerTable from "../../components/admin/banners/show-banners";
import SliderTable from "../../components/admin/sliders/show-sliders";
import Articels from "../../components/admin/articels/show-articels";
import { getApi, patchApi, deleteApi } from "../../service/api";
import CommentsTable from "../../components/admin/comments";
import Preloader from "../../components/loader/preloader";
// require("bootstrap/less/bootstrap.less");
const Index = ({ baseName, match }) => {
  // const [docs, setDocs] = useState([]);
  const [data, setData] = useState({});
  const [filter, setFilter] = useState({});
  const [loading, setloading] = useState(true);

  useEffect(async () => {
    try {
      //fetch
      getData({});
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getData = async (parametr) => {
    setloading(true);

    const fetchData = await getApi({
      path: parametr.path || baseName,
      params: { ...filter, ...(parametr.params ? parametr.params : {}) },
    });
    setFilter({ ...filter, ...parametr.params });
    setData(fetchData.data.data);
    setloading(false);

    // setDocs(fetchData.data.data.docs);
  };

  const handleFilter = (parametr) => {
    const filterQuery = {
      ...parametr.filter,
      page: 1,
    };
    getData({ path: parametr.path, params: filterQuery });
  };
  // const handleEditOneFeildV1 = async (parametr) => {
  //   try {
  //     await patchApi({
  //       path: parametr.path || baseName,
  //       body: parametr.body || {},
  //     });
  //     await getData({});
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };
  const handleEditOneFeild = async (parametr) => {
    // const key = Object.keys(parametr.body)[0];
    // const allData = { ...data };

    // const findIndex = allData.docs.findIndex(
    //   (item) => item._id === parametr.path.split("/").reverse()[0]
    // );
    // const findData = allData.docs[findIndex][key];
    // allData.docs[findIndex][key] = parametr.body[key];

    // const newData = allData;
    // setData(newData);
    try {
      await patchApi({
        path: parametr.path || baseName,
        body: parametr.body || {},
      });
      await getData({})
    } catch (err) {
      // allData.docs[findIndex][key] = findData;
      // const latesData = { ...allData };
      // setData(latesData);
    }
  };
  const handleRemove = async ({ path }) => {
    await deleteApi({ path });
    await getData({});
  };
  const propses = {
    // docs,
    data,
    getData,
    loading,
    baseName,
    handleRemove,
    handleFilter,
    handleEditOneFeild,
  };

  const component = {
    categories: <CategoryTable {...propses} />,
    users: <UsersTable {...propses} />,
    books: <BookTable {...propses} />,
    loans: <LonsTable {...propses} />,
    comments: <CommentsTable {...propses} />,
    sliders: <SliderTable {...propses} />,
    banners: <BannerTable {...propses} />,
    articels: <Articels {...propses} />,
  };

  return (
    <>
      <Preloader {...(loading ? { true: true } : {})} />
      {component[baseName]}
    </>
  );
};

export default Index;
