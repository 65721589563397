import React, { Fragment, useState } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Button,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import moment from "moment-jalali"
import Select from "react-select";
import Register from "./registerAlert";
import * as Yup from "yup";
import withReactContent from "sweetalert2-react-content";
import swal from "sweetalert";
import SwalV2 from "sweetalert2";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { getApi } from "../../../service/api";
import BtnLoader from "../../loader/btnLoader";

const MySwlV2 = withReactContent(SwalV2);

const LoanForm = (props) => {
  const [options, setOptions] = useState([]);
  const [bookOptions, setBookOptions] = useState([]);
  const [user, setUser] = useState({});
  const [book, setBook] = useState({});
  const [deliveryDate, setDeliveryDate] = useState();
  const [isSubmitBtnLoader, setIsSubmitBtnLoader] = useState(false);

  const handleSubmit = () => {
    if (!user?._id) {
      swal({
        title: "خطا",
        text: "کاربر را انتخاب کنید",
        icon: "error",
      });
      return;
    }
    if (!book?._id) {
      swal({
        title: "خطا",
        text: "کتاب را انتخاب کنید",
        icon: "error",
      });
      return;
    }
    if (!deliveryDate) {
      swal({
        title: "خطا",
        text: "تاریخ تحویل را انتخاب کنید",
        icon: "error",
      });
      return;
    }
    const date = new Date(deliveryDate);
    const nowDate = new Date();
    const year = date.getFullYear() >= nowDate.getFullYear();
    const mounth = date.getMonth() + 1 >= nowDate.getMonth() + 1;
    const day = date.getDate() >= nowDate.getDate();

  

    if (!year || !mounth || !day) {
      swal({
        title: "خطا",
        text: "تاریخ تحویل برای گذشته است",
        icon: "error",
      });
      return;
    }
    setIsSubmitBtnLoader(true);

    props.postData({
      path: props.baseName,
      body: {
        deliveryDate,
        book: book._id,
        user: user._id,
      },
    });
  };

  const onchangeInputUser = async (value) => {
    if (value < 4) {
      return;
    }
    const { data } = await getApi({
      path: "users/user",
      params: {
        mobileOrstudentId: value,
      },
    });

    setOptions(data.data.docs);
  };
  const onchangeInputBook = async (e) => {
    console.log(e);
    const { data } = await getApi({
      path: "books",
      params: {
        name: e,
      },
    });
    // console.log(data.data.docs)
    // const ss =[{
    //   name:"Al"
    // }]
    setBookOptions(data.data.docs);
  };

  const addUser = () => {
    MySwlV2.fire({
      html: <Register />,
      confirmButtonText: "بستن",
    });
  };
  return (
    <Fragment>
      <Formik
        initialValues={{
          user: props.data?.name || "",
          book: props.data?.name || "",
          deliveryDate: props.data?.name,
        }}
        onSubmit={handleSubmit}
      >
        <Form className="needs-validation">
          <Breadcrumb parent="امانت" title="ثبت امانت" />
          <Container fluid={true}>
            <Row>
              <Col sm="12">
                <Card>
                  <CardHeader>
                    <h5>{props.data ? "ویرایش امانت" : "ثبت امانت"}</h5>
                    <Button
                      onClick={addUser}
                      className="float-right bg-primary"
                    >
                      ثبت کاربر
                    </Button>
                  </CardHeader>
                  {/* <Form className="form theme-form" onSubmit={()=>}> */}
                  <CardBody>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label htmlFor="exampleFormControlInput1">
                            موبایل امانت گیرنده
                          </Label>
                          <Select
                            options={options}
                            // name="mobileOrstudentId"
                            onInputChange={(e) => {
                              onchangeInputUser(e);
                            }}
                            getOptionLabel={(option) => (
                              <span style={{ color: "black" }}>
                                {" "}
                                {option.name + " - " + option.mobile}
                              </span>
                            )}
                            getOptionValue={(option) => option._id}
                            onChange={(e) => setUser(e)}
                            filterOption={false}
                            // value={this.props.value}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label htmlFor="exampleFormControlInput1">
                            نام کتاب
                          </Label>
                          <Select
                            options={bookOptions}
                            name="book"
                            onInputChange={(e) => {
                              onchangeInputBook(e);
                            }}
                            getOptionLabel={(option) => (
                              <span style={{ color: "black" }}>
                                {" "}
                                {option.name +  (option?.author ? " - " + option?.author: "")}
                              </span>
                            )}
                            getOptionValue={(option) => option._id}
                            onChange={(e) => setBook(e)}
                            filterOption={false}
                            // value={this.props.value}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <FormGroup className="row">
                          <Label className="col-sm-3 col-form-label">
                            تاریخ تحویل
                          </Label>
                          <Col sm="9">
                            <Field
                              name="deliveryDate"
                              className="form-control digits"
                              id="example-datetime-local-input"
                              type="datetime-local" 
                              onChange={(dateFromValue) =>
                                setDeliveryDate(dateFromValue.target.value)
                              }
                            />
                          </Col>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <BtnLoader show={isSubmitBtnLoader} />
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Container>
        </Form>
      </Formik>
    </Fragment>
  );
};

export default LoanForm;
