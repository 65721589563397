import React, { Fragment } from "react";
import { Container, Row, Col } from "reactstrap";
const Footer = (props) => {
  return (
    <Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md="12" className="footer-copyright text-center">
              <a href="http://www.omidshayan.info" target={"_blank"}>

              <p className="mb-0">{"COPYRIGHT 2021 © OMID SHAYAN."}</p>
              </a>
            </Col>
          </Row>
        </Container>
      </footer>
    </Fragment>
  );
};

export default Footer;
