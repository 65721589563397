import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Alert,
  Button,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

import Select from "react-select";
import { X } from "react-feather";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { getApi } from "../../../service/api";
import { booksType } from "../../../constant";
import BtnLoader from "../../loader/btnLoader";
const SliderForm = (props) => {
  const [bookOptions, setBookOptions] = useState([]);
  const [book, setBooks] = useState([]);
  const [selectBooks, setSelectBooks] = useState(props.data?.books || []);
  const [isSubmitBtnLoader, setIsSubmitBtnLoader] = useState(false);
  const [getCatgories, setCategory] = useState([]);

  const handleSubmit = (values) => {
    setIsSubmitBtnLoader(true);

    const getSelctsBookId = selectBooks.map((item) => item._id);
    const requestInfo = {
      path: props.data ? `${props.baseName}/${props.data._id}` : props.baseName,
      body: {
        ...values,
        books: getSelctsBookId,
      },
    };
    if (!props.data) {
      //create
      props.postData(requestInfo);
    } else {
      //edite
      props.putData(requestInfo);
    }
  };

  const onchangeInputBook = async (e) => {
    const { data } = await getApi({
      path: "books",
      params: {
        name: e,
      },
    });
    setBookOptions(data.data.docs);
  };
  useEffect(async () => {
    try {
      const data = await getApi({ path: "/categories/whitOuthPaginate" });
      setCategory(data.data.data);
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <Fragment>
      <Formik
        initialValues={{
          name: props.data?.name || "",
          position: props.data?.position || 1,
          status: props.data?.status || "pending",
          category: props.data?.category || "",
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required(
            <span style={{ color: "red", padding: "2px" }}>عنوان الزامیست</span>
          ),
          category: Yup.string().required(
            <span style={{ color: "red", padding: "2px" }}>
              {" "}
              دسته بندی الزامیست
            </span>
          ),
        })}
        onSubmit={handleSubmit}
      >
        <Form className="needs-validation">
          <Breadcrumb title="ثبت اسلایدر" parent="اسلایدر" />
          <Container fluid={true}>
            <Row>
              <Col sm="12">
                <Card>
                  <CardHeader>
                    <h5>{props.data ? "ویرایش اسلایدر" : "ثبت اسلایدر"}</h5>
                  </CardHeader>
                  {/* <Form className="form theme-form" onSubmit={()=>}> */}
                  <CardBody>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label htmlFor="exampleFormControlInput1">
                            عنوان اسلایدر
                          </Label>
                          <Field
                            className="form-control"
                            name="name"
                            placeholder="عنوان اسلایدر"
                          />
                          <ErrorMessage name="name" />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="exampleFormControlInput1">
                            اولویت نمایش
                          </Label>
                          <Field
                            className="form-control"
                            name="position"
                            type="number"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label htmlFor="exampleFormControlSelect9">
                            وضعیت
                          </Label>

                          <Field
                            name="status"
                            as="select"
                            className="form-control digits"
                          >
                            <option value="pending">درانتظار تایید</option>
                            <option value="active">فعال</option>
                            <option value="inactive">غیرفعال</option>
                          </Field>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label htmlFor="exampleFormControlInput1">
                            نام دسته بندی
                          </Label>
                          <>
                            <Field
                              as="select"
                              name="category"
                              className="form-control digits"
                            >
                              <option value={""}>
                                دسته بندی را انتخاب کنید
                              </option>
                              {getCatgories.map((item) => {
                                return (
                                  <option key={item._id} value={item._id}>
                                    {item.name}
                                  </option>
                                );
                              })}
                            </Field>
                            <ErrorMessage name="category" />
                          </>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label htmlFor="exampleFormControlInput1">
                            نام کتاب
                          </Label>
                          <Select
                            options={bookOptions}
                            name="books"
                            onInputChange={(e) => {
                              onchangeInputBook(e);
                            }}
                            placeholder="... جستجوی کتاب"
                            getOptionLabel={(option) => (
                              <div
                                style={{ cursor: "pointer", color: "black" }}
                              >
                                {" "}
                                <span className="bg-primary m-2 p-1 rounded">
                                  {"نام کتاب :  " + option.name} |{" "}
                                  {"نویسنده :  " +
                                    (option.author
                                      ? option.author
                                      : "نامشخص")}{" "}
                                  |{" "}
                                  {"نوع کتاب : " +
                                    (booksType[option.type] || "نامشخص")}
                                </span>
                              </div>
                            )}
                            getOptionValue={(option) => option._id}
                            onChange={(e) => {
                              const books = [...selectBooks, e];
                              setSelectBooks(books);
                            }}
                            filterOption={false}
                            // value={this.props.value}
                          />
                          <ErrorMessage name="books" />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        {selectBooks.length ? (
                          <Label>کتاب های این اسلایدر</Label>
                        ) : null}
                        {selectBooks.map((item, index) => {
                          return (
                            <Alert
                              key={index}
                              className="alert-dismissible"
                              color={"info dark"}
                              isOpen={true}
                              target={"Aler"}
                            >
                              <strong>
                                <div style={{ color: "black" }}>
                                  {" "}
                                  <span className="bg-primary m-2 p-1 rounded">
                                    {"نام کتاب :  " + item.name} |{" "}
                                    {"نویسنده :  " +
                                      (item.author
                                        ? item.author
                                        : "نامشخص")}{" "}
                                    |{" "}
                                    {"نوع کتاب : " +
                                      (booksType[item.type] || "نامشخص")}
                                  </span>
                                </div>
                              </strong>
                              <span
                                className="float-right btn  mt-3"
                                onClick={() =>
                                  setSelectBooks(
                                    selectBooks.filter(
                                      (i) => i._id !== item._id
                                    )
                                  )
                                }
                              >
                                <X color="red" />
                              </span>
                            </Alert>
                          );
                        })}
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <BtnLoader show={isSubmitBtnLoader} />
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Container>
        </Form>
      </Formik>
    </Fragment>
  );
};

export default SliderForm;
