import React from "react";

const BtnLoader = (props) => {
  return (
    <>
      {props.show ? (
        <button className="btn btn-primary" type="button" disabled>
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          درحال ثبت...
        </button>
      ) : (
        <button type="submit" color="primary" className="mr-1 btn btn-primary">
          ثبت
        </button>
      )}
    </>
  );
};

export default BtnLoader;
