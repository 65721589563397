import React, { useEffect, useState } from "react";
import CategoryForm from "../../components/admin/grouping/insert-group";
import BooksForm from "../../components/admin/books/edite-book";
import SlidersForm from "../../components/admin/sliders/form";
import BannerForm from "../../components/admin/banners/form";
import { withRouter } from "react-router-dom";
import { putApi } from "../../service/api";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";

const Index = ({ baseName, location }) => {
  const [data, setData] = useState(location.state?.data);

  const router = useHistory();
  const putData = async ({ path, body }) => {
    try {
      await putApi({ path, body });
      router.push(`/admin/show-${baseName}/Arya`);
      swal({
        title: "موفق",
        // text: "You clicked the button!",
        icon: "success",
      });
    } catch (err) {
      console.log(err.propses);
    }
  };
  const propses = {
    baseName,
    putData,
    data,
  };
  const component = {
    sliders: <SlidersForm {...propses} />,
    categories: <CategoryForm {...propses} />,
    books: <BooksForm {...propses} />,
    banners: <BannerForm {...propses} />,
  };

  return <>{component[baseName]}</>;
};

export default withRouter(Index);
