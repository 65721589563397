import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
} from "reactstrap";
import { Twitter, Facebook, GitHub } from "react-feather";
import {
  Password,
  SignIn,
  EmailAddress,
  RememberPassword,
  ForgotPassword,
  CreateAccount,
  FIREBASE,
  AUTH0,
  JWT,
} from "../../constant";
import jsCookies from "js-cookie";
import { postApi } from "../../service/api";
const Logins = (props) => {
  const [togglePassword, setTogglePassword] = useState(false);
  const [password, setPassword] = useState("");
  const [mobileOrStudentId, setMobileOrStudentId] = useState("");
  const [selected, setSelected] = useState("firebase");

  const handleChange = (e) => {
    setPassword(e.target.value);
  };
  const HideShowPassword = (tPassword) => {
    setTogglePassword(!tPassword);
  };
  const Login = async () => {
    try {
      const signIn = await postApi({
        path: "/auth/login",
        body: {
          mobile: mobileOrStudentId,
          password,
        },
      });

      jsCookies.set("token", signIn?.data?.token);
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Container fluid={true} className="p-0">
      <Row className="m-0">
        <Col xs="12" className="p-0">
          <div className="login-card">
            <div>
              <div>
                <a className="logo" href="#javascript">
                  <img
                    width={"200px"}
                    height="200px"
                    className="img-fluid for-light"
                    src={require("../../assets/images/logo/login.png")}
                    alt=""
                  />
                  <img
                    width={"200px"}
                    height="200px"
                    className="img-fluid for-dark"
                    src={require("../../assets/images/logo/logo_dark.png")}
                    alt=""
                  />
                </a>
              </div>
              <div className="login-main">
                <TabContent activeTab={selected} className="content-login">
                  <TabPane
                    className="fade show"
                    tabId={selected === "firebase" ? "firebase" : "jwt"}
                  >
                    <div
                      className="theme-form"
                      style={{ textAlign: "right", direction: "rtl" }}
                    >
                      <h4>{"ورود"}</h4>
                      <p>{"شماره موبایل و رمز عبور خود را وارد کنید"}</p>
                      <FormGroup>
                        <Label className="col-form-label">موبایل</Label>
                        <Input
                          className="form-control"
                          type="text"
                          required
                          onChange={(e) => setMobileOrStudentId(e.target.value)}
                          placeholder="079........"
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label className="col-form-label">رمز</Label>
                        <Input
                          className="form-control"
                          type={togglePassword ? "text" : "password"}
                          name="login[password]"
                          value={password}
                          onChange={(e) => handleChange(e)}
                          required=""
                          placeholder="*********"
                        />
                        {/* <div
                       
                          className="show-hide"
                          onClick={() => HideShowPassword(togglePassword)}
                        >
                          <span   className={togglePassword ? "" : "show"}></span>
                        </div> */}
                      </FormGroup>
                      <div className="form-group mb-0">
                        {/* <div className="checkbox ml-3">
                          <Input id="checkbox1" type="checkbox" />
                          <Label className="text-muted" for="checkbox1">
                            {RememberPassword}
                          </Label>
                        </div> */}
                        {/* <a className="link" href="#javascript">
                          {ForgotPassword}
                        </a> */}
                        {/* {selected === "firebase" ?
                          <Button color="primary" className="btn-block" >{SignIn}</Button>
                          : */}

                        <Button
                          color="primary"
                          onClick={Login}
                          className="btn-block"
                        >
                          ورود
                        </Button>
                        {/* } */}
                      </div>
                    </div>
                  </TabPane>
                </TabContent>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Logins;
