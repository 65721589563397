import React, { useState } from "react";
import {ChevronLeft ,ChevronRight} from "react-feather"
const Paginate = ({ page, pages, changePage, total }) => {
  const totlaLable = "مجموع";
  return (
    <>
      {total ? (
        <div className="btn-toolbar mt-4" role="toolbar">
          <div className="btn-group">
            {+page > 1 ? (
              <button
                type="button"
                onClick={() => changePage(+page - 1)}
                className="btn btn-default color-blue"
              >
               <ChevronRight />
              </button>
            ) : null}
            <button type="button" className="btn btn-default text-faded">
              {page} / {pages}
            </button>
            {pages == page ? null : (
              <button
                onClick={() => changePage(+page + 1)}
                type="button"
                className="btn btn-default color-blue"
              >
                <ChevronLeft />
              </button>
            )}
          </div>
          <button type="button" className="btn btn-primary text-faded ml-4">
            <span> {totlaLable} </span> : <span> {total} </span>
          </button>
        </div>
      ) : null}
    </>
  );
};

export default Paginate;
