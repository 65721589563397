import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import { X } from "react-feather";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Alert,
  CardBody,
  CardFooter,
  Button,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import Select from "react-select/async";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import ImageUploader from "react-images-upload";
import BtnLoader from "../../loader/btnLoader";

const { getApi, postApi } = require("../../../service/api");
const BaseInput = (props) => {
  const [getCatgories, setCategories] = useState([]);
  const [heading, setHeaders] = useState([]);
  const [files, setFiles] = useState([]);
  const [audio, setAudio] = useState([]);
  const [images, setImages] = useState([]);
  const [bookHeader, setBookHeader] = useState({});
  const [isSubmitBtnLoader, setIsSubmitBtnLoader] = useState(false);

  const handleSubmit = async (values) => {
    setIsSubmitBtnLoader(true);

    try {
      const feilds = {
        ...values,
        heading: JSON.stringify(heading),
        categories: values.categories.slice(","),
        audio,
        images,
        files,
      };

      const formData = new FormData();
      Object.keys(feilds).map((keyName) => {
        if (feilds[keyName]) {
          if (["images", "files", "audio", "categories"].includes(keyName)) {
            if (feilds[keyName].length)
              for (let index = 0; index < feilds[keyName].length; index++) {
                const element = feilds[keyName][index];
                formData.append(keyName, element);
              }
          } else if (keyName !== "lang") {
            formData.append(keyName, feilds[keyName]);
          }
        }
      });
      feilds.lang.map((item, index) => {
        formData.append("lang", feilds["lang"][index]);
      });
      const requestInfo = {
        path: props.baseName,

        body: formData,
      };

      props.postData(requestInfo);
    } catch (err) {
      setIsSubmitBtnLoader(false);

      console.log(err);
    }
  };
  useEffect(async () => {
    try {
      const data = await getApi({ path: "/categories/whitOuthPaginate" });
      console.log(data);
      setCategories(data.data.data);
    } catch (err) {
      console.log(err);
    }
  }, []);
  return (
    <Formik
      initialValues={{
        name: "",
        status: "pending",
        categories: [],
        readingTime: "",
        author: "",
        lang: [],
        description: "",
        translator: "",
        totalPage: "",
        releaseDate: "",
        part: "",
        elmari: "",
        totalBook: "",
        nowInventoryBooks: "",
        floor: "",
        shabok: "",
        nasher: "",
        martabeVirayesh: "",
        nobatChap: "",
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required(
          <span style={{ color: "red", padding: "2px" }}>نام الزامیست</span>
        ),
      })}
      onSubmit={handleSubmit}
    >
      <Form className="needs-validation">
        <Breadcrumb parent="کتاب" title="ثبت کتاب " />
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader>
                  <h5>{props.data ? "ویرایش کتاب" : "ثبت کتاب"}</h5>
                </CardHeader>

                <CardBody>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label htmlFor="exampleFormControlInput1">
                          نام کتاب
                        </Label>
                        <Field
                          className="form-control"
                          name="name"
                          placeholder="نام کتاب"
                        />
                        <ErrorMessage name="name" />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <FormGroup>
                        <Label htmlFor="exampleFormControlInput1">
                          نام نویسنده
                        </Label>
                        <Field
                          className="form-control"
                          name="author"
                          placeholder="نام نویسنده کتاب"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">
                          دسته بندی کتاب
                        </Label>

                        <Field
                          as="select"
                          name="categories"
                          className="form-control digits"
                          multiple={true}
                        >
                          {getCatgories.map((item) => {
                            return (
                              <option key={item._id} value={item._id}>
                                {item.name}
                              </option>
                            );
                          })}
                        </Field>
                        <ErrorMessage name="categories" />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect3">
                          زبان کتاب
                        </Label>
                        <Field
                          as="select"
                          name="lang"
                          className="form-control digits"
                          multiple={true}
                        >
                          <option value="">{"نامشخص"}</option>
                          <option value="en">{"انگلیسی"}</option>
                          <option value="dari">{"دری"}</option>
                          <option value="pashto">{"پشتو"}</option>
                        </Field>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label htmlFor="exampleFormControlInput1">
                          مدت زمان خواندن
                        </Label>
                        <Field
                          className="form-control"
                          name="readingTime"
                          placeholder="مدت زمان خواندن"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">وضعیت</Label>
                        <Field
                          as="select"
                          name="status"
                          className="form-control digits"
                          defaultValue="1"
                        >
                          <option value="active">{"فعال"}</option>
                          <option value="inactive">{"غیر فعال"}</option>
                          <option value="pending">{"منتظر تایید"}</option>
                        </Field>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>توضیحات</Label>
                        <Field
                          component="textarea"
                          name={"description"}
                          className="form-control"
                          rows="4"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Card className="pt-2 pb-2">
                        <FormGroup className="m-2">
                          <Label>سرفصل</Label>
                          <Field
                            type="text"
                            name="heading"
                            onChange={(e) =>
                              setBookHeader({ name: e.target.value })
                            }
                            className="form-control"
                            rows="3"
                          />
                        </FormGroup>
                        <Button
                          color="success"
                          onClick={() => setHeaders([...heading, bookHeader])}
                          className="m-5 w-20"
                        >
                          افزودن
                        </Button>
                        <Col>
                          {heading.length ? <Label>لیست سرفصل ها</Label> : null}
                          {heading &&
                            heading.map((item, index) => {
                              return (
                                <Alert
                                  key={index}
                                  className="alert-dismissible"
                                  color={"info dark"}
                                  isOpen={true}
                                  target={"Aler"}
                                >
                                  <strong>{item.name} </strong>
                                  <span
                                    className="float-right btn"
                                    onClick={() =>
                                      setHeaders(
                                        heading.filter(
                                          (i) => i.name !== item.name
                                        )
                                      )
                                    }
                                  >
                                    <X color="red" />
                                  </span>
                                </Alert>
                              );
                            })}
                        </Col>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>مترجم</Label>
                        <Field
                          type="text"
                          name="translator"
                          className="form-control"
                          rows="3"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>تعداد صفحات</Label>
                        <Field
                          type="text"
                          name="totalPage"
                          className="form-control"
                          rows="3"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>تعداد کل موجودی کتاب</Label>
                        <Field
                          type="number"
                          name="totalBook"
                          className="form-control"
                          rows="3"
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="12" md="6">
                      <FormGroup>
                        <Label>شابک</Label>
                        <Field
                          type="string"
                          name="shabok"
                          className="form-control"
                          rows="3"
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="12" md="6">
                      <FormGroup>
                        <Label>ناشر</Label>
                        <Field
                          type="string"
                          name="nasher"
                          className="form-control"
                          rows="3"
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="12" md="6">
                      <FormGroup>
                        <Label>مرتبه ویرایش</Label>
                        <Field
                          type="string"
                          name="martabeVirayesh"
                          className="form-control"
                          rows="3"
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="12" md="6">
                      <FormGroup>
                        <Label>نوبت چاپ</Label>
                        <Field
                          type="string"
                          name="nobatChap"
                          className="form-control"
                          rows="3"
                        />
                      </FormGroup>
                    </Col>

                    <Col sm="12" md="6">
                      <FormGroup>
                        <Label>طبقه</Label>
                        <Field
                          type="text"
                          name="floor"
                          className="form-control"
                          rows="3"
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="12" md="6">
                      <FormGroup>
                        <Label>شماره</Label>
                        <Field
                          type="text"
                          name="part"
                          className="form-control"
                          rows="3"
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="12" md="6">
                      <FormGroup>
                        <Label>حرف</Label>
                        <Field
                          type="text"
                          name="erf"
                          className="form-control"
                          rows="3"
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="12" md="6">
                      <FormGroup>
                        <Label>الماری</Label>
                        <Field
                          type="text"
                          name="elmari"
                          className="form-control"
                          rows="3"
                        />
                      </FormGroup>
                    </Col>

                    <Col md="6" sm="12">
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">
                          تاریخ انتشار
                        </Label>

                        <Field
                          type="text"
                          name="releaseDate"
                          className="form-control"
                          rows="3"
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Card>
                    <Row className="p-2">
                      <Col>
                        <Label className="h4 col-form-label mb-5">
                          بارگذاری فایل
                        </Label>

                        <FormGroup className="row">
                          <Label className="col-sm-3 col-form-label">
                            عکس هاس کتاب
                          </Label>

                          <Col sm="12">
                            <ImageUploader
                              withIcon={true}
                              withPreview={true}
                              label="حداکثر حجم عکس 1 مگابایت | فرمت عکس ها jpg - png - jpeg"
                              fileTypeError="فرمت عکس اشتباه است"
                              fileSizeError="حجم عکس بیشتر از حد مجاز است"
                              buttonText="عکس را انتخاب کنید"
                              onChange={(e) => setImages(e)}
                              imgExtension={[
                                ".jpg",
                                ".gif",
                                ".png",
                                ".gif",
                                ".jpeg",
                              ]}
                              maxFileSize={5242880}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup className="row">
                          <Label className="col-sm-3 col-form-label">
                            فایل pdf
                          </Label>
                          <Col sm="9">
                            <Input
                              className="form-control"
                              multiple
                              name="file"
                              type="file"
                              accept=".pdf,.txt,.doc"
                              onChange={(e) => setFiles(e.target.files)}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup className="row">
                          <Label className="col-sm-3 col-form-label">
                            فایل صوتی
                          </Label>
                          <Col sm="9">
                            <Input
                              className="form-control"
                              multiple
                              accept=".mp3,.wav,.wma,.aac,.m4a,.flac"
                              onChange={(e) => setAudio(e.target.files)}
                              name="audio"
                              type="file"
                            />
                          </Col>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Card>
                </CardBody>
                <CardFooter>
                  <BtnLoader show={isSubmitBtnLoader} />
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
      </Form>
    </Formik>
  );
};

export default BaseInput;
