import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import Login from "./login";
import jsCookie from "js-cookie";
import jwt from "jsonwebtoken";
import { getRoles } from "@testing-library/dom";

class RequireAuth extends Component {
  state = { user: null };

  componentDidMount = () => {
    const token = jsCookie.get("token");
    if (token) {
      const user = jwt.verify(token, "SECRRET_KY_JWT");
      if (user && user.role === "admin") {
        this.setState({ user });
      }
    }

    // this.props.history.push("/");
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.props.location.pathname !== prevProps.location.pathname &&
      !this.state.user
    ) {
      this.props.history.push("/");
    }
  };

  render = () =>
    !this.state.user ? (
      <Login setUserAuth={this.setUserAuth} />
    ) : (
      <Fragment>{this.props.children}</Fragment>
    );
}

export default withRouter(RequireAuth);
