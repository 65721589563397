import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Alert,
  Button,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

import Select from "react-select";
import * as Yup from "yup";
import ImageUploader from "react-images-upload";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { getApi } from "../../../service/api";
import BtnLoader from "../../loader/btnLoader";
import { ServerUrl } from "../../../config";

const LoanForm = (props) => {
  const [bannerImage, setBannerImage] = useState([]);
  const [getCatgories, setCategory] = useState([]);
  const [isSubmitBtnLoader, setIsSubmitBtnLoader] = useState(false);

  const handleSubmit = (values) => {
    setIsSubmitBtnLoader(true);

    const formData = new FormData();
    formData.append("status", values.status);
    formData.append("position", values.position);
    formData.append("category", values.category);
    bannerImage[0] && formData.append("bannerImage", bannerImage[0]);

    const requestInfo = {
      path: props.data ? `${props.baseName}/${props.data._id}` : props.baseName,
      body: formData,
    };
    if (!props.data) {
      //create
      props.postData(requestInfo);
    } else {
      //edite
      props.putData(requestInfo);
    }
  };

  useEffect(async () => {
    try {
      const data = await getApi({ path: "/categories/whitOuthPaginate" });
      setCategory(data.data.data);
    } catch (err) {
      console.log(err);
    }
  }, []);
  return (
    <Fragment>
      <Formik
        initialValues={{
          // name: props.data?.name || "",
          position: props.data?.position || 1,
          status: props.data?.status || "pending",
          category: props.data?.category._id || "",
        }}
        validationSchema={Yup.object().shape({
          category: Yup.string().required(
            <span style={{ color: "red", padding: "2px" }}>
              {" "}
              دسته بندی الزامیست
            </span>
          ),
        })}
        onSubmit={handleSubmit}
      >
        <Form className="needs-validation">
          <Breadcrumb title="ثبت بنر" parent="بنر" />
          <Container fluid={true}>
            <Row>
              <Col sm="12">
                <Card>
                  <CardHeader>
                    <h5>{props.data ? "ویرایش بنر" : "ثبت بنر"}</h5>
                  </CardHeader>
                  {/* <Form className="form theme-form" onSubmit={()=>}> */}
                  <CardBody>
                    <Row>
                      {/* <Col>
                        <FormGroup>
                          <Label htmlFor="exampleFormControlInput1">
                            عنوان بنر
                          </Label>
                          <Field
                            className="form-control"
                            name="name"
                            placeholder="عنوان بنر"
                          />
                          <ErrorMessage name="name" />
                        </FormGroup>
                      </Col> */}
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="exampleFormControlInput1">
                            ردیف نمایش
                          </Label>
                          <Field
                            className="form-control"
                            name="position"
                            type="number"
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="12" md="6">
                        <FormGroup>
                          <Label htmlFor="exampleFormControlSelect9">
                            وضعیت
                          </Label>

                          <Field
                            name="status"
                            as="select"
                            className="form-control digits"
                          >
                            <option value="pending">درانتظار تایید</option>
                            <option value="active">فعال</option>
                            <option value="inactive">غیرفعال</option>
                          </Field>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <FormGroup>
                          <Label htmlFor="exampleFormControlInput1">
                            نام دسته بندی
                          </Label>
                          <>
                            <Field
                              as="select"
                              name="category"
                              className="form-control digits"
                            >
                              <option value={""}>
                                دسته بندی را انتخاب کنید
                              </option>
                              {getCatgories.map((item) => {
                                return (
                                  <option key={item._id} value={item._id}>
                                    {item.name}
                                  </option>
                                );
                              })}
                            </Field>
                            <ErrorMessage name="category" />
                          </>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      {props.data ? (
                        <>
                          <Label>عکس فعلی : </Label>
                          <Col sm="12" className="p-5 bg-primary m-5 rounded">
                            <img
                              width={"100%"}
                              src={`${ServerUrl}/${props.data.image}`}
                            />
                          </Col>
                        </>
                      ) : null}

                      <Col sm="12">
                        <Label className="mt-2">
                          {props.data
                            ? " جایگزین کردن عکس بنر : "
                            : "آپلود عکس : "}
                        </Label>
                        <ImageUploader
                          withIcon={true}
                          withPreview={true}
                          singleImage={true}
                          label="حداکثر حجم عکس 1 مگابایت | فرمت عکس ها jpg - png - jpeg"
                          fileTypeError="فرمت عکس اشتباه است"
                          fileSizeError="حجم عکس بیشتر از حد مجاز است"
                          buttonText="انتخاب عکس"
                          onChange={(e) => setBannerImage(e)}
                          imgExtension={[
                            ".jpg",
                            ".gif",
                            ".png",
                            ".gif",
                            ".jpeg",
                          ]}
                          maxFileSize={1242880}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <BtnLoader show={isSubmitBtnLoader} />
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Container>
        </Form>
      </Formik>
    </Fragment>
  );
};

export default LoanForm;
