import React, { Fragment, useState, useCallback, useMemo } from "react";
// import Breadcrumb from '../../layout/breadcrumb'
import differenceBy from "lodash/differenceBy";
import Breadcrumb from "../../../layout/breadcrumb/index";
import Jalali from "moment-jalali";
// import { statusTypes } from "../../../constant/index";
// import sweetalert from "sweetalert";
import DataTable from "react-data-table-component";
// import {tableData} from '../../data/dummyTableData'
// import { Popover } from "react-tiny-popover";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Input,
  Button,
} from "reactstrap";
import Paginate from "../../paginate";
import { Edit, Trash } from "react-feather";
import { Link } from "react-router-dom";
import swal from "sweetalert";

export default function ManageSelections(props) {
  const [rowChangePosition, setRowChangePosition] = useState({});
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const tableColumns = [
    {
      name: "لینک دسته بندی",
      selector: (row) => row.category?.name,
      minWidth: "200px",
    },
    {
      name: "موقعیت",
      selector: "position",
    },

    {
      name: "تاریخ ثبت",
      selector: (row) => {
        return Jalali(row.createdAt, "YYYY-M-D HH:mm:ss").format("jYYYY/jM/jD");
      },
    },
    {
      name: "وضعیت",
      selector: (row) => {
        return (
          <>
            {row.status === "active" ? (
              <span className="text-success">فعال</span>
            ) : null}
            {row.status === "inactive" ? (
              <span className="text-danger">غیرفعال</span>
            ) : null}
            {row.status === "pending" ? <span>نامشخص</span> : null}
          </>
        );
      },
    },

    {
      name: "تغییر وضعیت",
      allowOverflow: true,
      button: true,
      cell: (row) => {
        return (
          <div
            onClick={() =>
              props.handleEditOneFeild({
                path: `${props.baseName}/${row._id}`,
                body: {
                  status: row.status === "active" ? "inactive" : "active",
                },
              })
            }
          >
            {row.status === "inactive" || row.status === "pending" ? (
              <button className="btn btn-primary p-1" raised primary>
                فعال شود
              </button>
            ) : (
              <button className="btn btn-primary p-1" raised primary>
                غیرفعال شود
              </button>
            )}
          </div>
        );
      },
    },
    {
      name: "ویرایش",
      allowOverflow: true,
      cell: (row) => {
        return (
          <Link
            to={{
              pathname: `/admin/edite-${props.baseName}/${row._id}`,
              search: "?sort=name",
              hash: "#the-hash",
              state: { data: { ...row } },
            }}
          >
            <Edit className="text-info" />
          </Link>
        );
      },
    },
    {
      name: "حذف",
      cell: (row) => {
        return (
          <>
            <Trash
              className="text-danger"
              style={{ cursor: "pointer" }}
              onClick={() => {
                swal({
                  title: "آیا مطمئنید؟",
                  text: "شما در حال حذف این بنر میباشید",
                  icon: "warning",
                  buttons: ["کنسل شود!", "بله, حذف شود"],
                  dangerMode: true,
                }).then(function (isConfirm) {
                  if (isConfirm) {
                    props.handleRemove({
                      path: `${props.baseName}/${row._id}`,
                    });
                  }
                });
              }}
            />
          </>
        );
      },
    },
  ];

  return (
    <Fragment>
      <Breadcrumb parent="بنر" title="بنر ها" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>{"نمایش تمامی بنر ها"}</h5>
              </CardHeader>
              <CardBody>
                <DataTable
                  data={props.data?.docs}
                  columns={tableColumns}
                  striped={true}
                  noDataComponent="موردی یافت نشد"
                  center={true}
                  persistTableHead
                />
                <Paginate
                  pages={props.data.pages}
                  page={props.data.page}
                  total={props.data.total}
                  changePage={(e) =>
                    props.getData({
                      params: { page: e },
                    })
                  }
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}
