import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import { Trash, X } from "react-feather";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Alert,
  CardBody,
  CardFooter,
  Button,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import Select from "react-select/async";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import ImageUploader from "react-images-upload";
import momentJalali from "moment-jalali";
import BtnLoader from "../../loader/btnLoader";
import { ServerUrl } from "../../../config/index.json";
const { getApi, postApi } = require("../../../service/api");

const EditBook = (props) => {
  const [getCatgories, setCategories] = useState([]);
  const [heading, setHeaders] = useState(props.data?.heading || []);
  const [removeFiles, setRemoveFiles] = useState([]);
  const [removeAudio, setRemoveAudio] = useState([]);
  const [removeImages, setremoveImages] = useState([]);
  const [dataFiles, setDataFiles] = useState(props.data?.files || []);
  const [dataAudio, setDataAudio] = useState(props.data?.audio || []);
  const [dataImages, setDataImages] = useState(props.data?.images || []);
  const [files, setFiles] = useState([]);
  const [audio, setAudio] = useState([]);
  const [images, setImages] = useState([]);
  const [isSubmitBtnLoader, setIsSubmitBtnLoader] = useState(false);

  const [bookHeader, setBookHeader] = useState({});

  const handleSubmit = async (values) => {
    // setIsSubmitBtnLoader(true);

    try {
      const feilds = {
        ...values,
        heading: JSON.stringify(heading),
        audio,
        images,
        files,
        dataAudio: JSON.stringify(dataAudio),
        dataFiles: JSON.stringify(dataFiles),
        dataImages: JSON.stringify(dataImages),
        removeFiles,
        removeImages,
        removeAudio,
      };
      console.log("_______________", feilds);
      const formData = new FormData();
      Object.keys(feilds).map((keyName) => {
        if (feilds[keyName]) {
          if (["images", "files", "audio"].includes(keyName)) {
            if (feilds[keyName].length)
              for (let index = 0; index < feilds[keyName].length; index++) {
                const element = feilds[keyName][index];
                formData.append(keyName, element);
              }
          } else if (keyName !== "lang") {
            formData.append(keyName, feilds[keyName]);
          }
        }
      });

      ["lang", "removeAudio", "removeFiles", "removeImages"].map(
        (FeildName) => {
          feilds[FeildName] &&
            feilds[FeildName].map((item) => {
              formData.append(FeildName, item);
            });
        }
      );
      const requestInfo = {
        path: `${props.baseName}/${props.data._id}`,
        body: formData,
      };

      props.putData(requestInfo);
    } catch (err) {
      setIsSubmitBtnLoader(false);
      console.log(err);
    }
  };
  useEffect(async () => {
    try {
      const data = await getApi({ path: "/categories/whitOuthPaginate" });
      console.log(data);
      setCategories(data.data.data);
    } catch (err) {
      console.log(err);
    }
  }, []);
  return (
    <Formik
      initialValues={{
        name: props.data?.name || "",
        status: props.data?.status || "pending",
        categories: props.data?.categories?.map((item) => item._id) || [],
        readingTime: props.data?.readingTime,
        author: props.data?.author,
        lang: props.data?.lang || [],
        description: props.data?.description,
        translator: props.data?.translator,
        totalPage: props.data?.totalPage,
        releaseDate: props.data?.releaseDate,
        erf: props.data?.erf,
        shabok: props.data?.shabok,
        nasher: props.data?.nasher,
        martabeVirayesh: props.data?.martabeVirayesh,
        nobatChap: props.data?.nobatChap,
        part: props.data?.part,
        elmari: props.data?.elmari,
        totalBook: props.data?.totalBook,
        nowInventoryBooks: props.data?.nowInventoryBooks,
        floor: props.data?.floor,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required(
          <span style={{ color: "red", padding: "2px" }}>نام الزامیست</span>
        ),
      })}
      onSubmit={handleSubmit}
    >
      <Form className="needs-validation">
        <Breadcrumb parent="کتاب" title="ثبت کتاب " />
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader>
                  <h5>{props.data ? "ویرایش کتاب" : "ثبت کتاب"}</h5>
                </CardHeader>

                <CardBody>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label htmlFor="exampleFormControlInput1">
                          نام کتاب
                        </Label>
                        <Field
                          className="form-control"
                          name="name"
                          placeholder="نام کتاب"
                        />
                        <ErrorMessage name="name" />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <FormGroup>
                        <Label htmlFor="exampleFormControlInput1">
                          نام نویسنده
                        </Label>
                        <Field
                          className="form-control"
                          name="author"
                          placeholder="نام نویسنده کتاب"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">
                          دسته بندی کتاب
                        </Label>

                        <Field
                          as="select"
                          name="categories"
                          className="form-control digits"
                          multiple={true}
                        >
                          {getCatgories.map((item) => {
                            return (
                              <option key={item._id} value={item._id}>
                                {item.name}
                              </option>
                            );
                          })}
                        </Field>
                        <ErrorMessage name="categories" />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect3">
                          زبان کتاب
                        </Label>
                        <Field
                          as="select"
                          name="lang"
                          className="form-control digits"
                          multiple={true}
                        >
                          <option value="">{"نامشخص"}</option>
                          <option value="en">{"انگلیسی"}</option>
                          <option value="dari">{"دری"}</option>
                          <option value="pashto">{"پشتو"}</option>
                        </Field>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label htmlFor="exampleFormControlInput1">
                          مدت زمان خواندن
                        </Label>
                        <Field
                          className="form-control"
                          name="readingTime"
                          placeholder="مدت زمان خواندن"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">وضعیت</Label>
                        <Field
                          as="select"
                          name="status"
                          className="form-control digits"
                          defaultValue="1"
                        >
                          <option value="active">{"فعال"}</option>
                          <option value="inactive">{"غیر فعال"}</option>
                          <option value="pending">{"منتظر تایید"}</option>
                        </Field>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>توضیحات</Label>
                        <Field
                          component="textarea"
                          name={"description"}
                          className="form-control"
                          rows="4"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Card className="pt-2 pb-2">
                        <FormGroup className="m-2">
                          <Label>سرفصل</Label>
                          <Field
                            type="text"
                            name="heading"
                            onChange={(e) =>
                              setBookHeader({ name: e.target.value })
                            }
                            className="form-control"
                            rows="3"
                          />
                        </FormGroup>
                        <Button
                          color="success"
                          onClick={() => setHeaders([...heading, bookHeader])}
                          className="m-5 w-20"
                        >
                          افزودن
                        </Button>
                        <Col>
                          {heading.length ? <Label>لیست سرفصل ها</Label> : null}
                          {heading &&
                            heading.map((item, index) => {
                              return (
                                <Alert
                                  key={index}
                                  className="alert-dismissible"
                                  color={"info dark"}
                                  isOpen={true}
                                  target={"Aler"}
                                >
                                  <strong>{item.name} </strong>
                                  <span
                                    className="float-right btn"
                                    onClick={() =>
                                      setHeaders(
                                        heading.filter(
                                          (i) => i.name !== item.name
                                        )
                                      )
                                    }
                                  >
                                    <X color="red" />
                                  </span>
                                </Alert>
                              );
                            })}
                        </Col>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>مترجم</Label>
                        <Field
                          type="text"
                          name="translator"
                          className="form-control"
                          rows="3"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>تعداد صفحات</Label>
                        <Field
                          type="text"
                          name="totalPage"
                          className="form-control"
                          rows="3"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>تعداد کل موجودی کتاب</Label>
                        <Field
                          type="number"
                          name="totalBook"
                          className="form-control"
                          rows="3"
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="12" md="6">
                      <FormGroup>
                        <Label>شابک</Label>
                        <Field
                          type="string"
                          name="shabok"
                          className="form-control"
                          rows="3"
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="12" md="6">
                      <FormGroup>
                        <Label>ناشر</Label>
                        <Field
                          type="string"
                          name="nasher"
                          className="form-control"
                          rows="3"
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="12" md="6">
                      <FormGroup>
                        <Label>مرتبه ویرایش</Label>
                        <Field
                          type="string"
                          name="martabeVirayesh"
                          className="form-control"
                          rows="3"
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="12" md="6">
                      <FormGroup>
                        <Label>نوبت چاپ</Label>
                        <Field
                          type="string"
                          name="nobatChap"
                          className="form-control"
                          rows="3"
                        />
                      </FormGroup>
                    </Col>

                    <Col sm="12" md="6">
                      <FormGroup>
                        <Label>طبقه</Label>
                        <Field
                          type="text"
                          name="floor"
                          className="form-control"
                          rows="3"
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="12" md="6">
                      <FormGroup>
                        <Label>شماره</Label>
                        <Field
                          type="text"
                          name="part"
                          className="form-control"
                          rows="3"
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="12" md="6">
                      <FormGroup>
                        <Label>حرف</Label>
                        <Field
                          type="text"
                          name="erf"
                          className="form-control"
                          rows="3"
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="12" md="6">
                      <FormGroup>
                        <Label>الماری</Label>
                        <Field
                          type="text"
                          name="elmari"
                          className="form-control"
                          rows="3"
                        />
                      </FormGroup>
                    </Col>

                    <Col md="6" sm="12">
                      <FormGroup className="row">
                        <Label className="col-sm-3 col-form-label">
                          تاریخ انتشار
                        </Label>

                        <Field
                          type="text"
                          name="releaseDate"
                          className="form-control"
                          rows="3"
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Card>
                    <Row className="p-2">
                      <Col>
                        <Label className="h4 col-form-label mb-5">
                          بارگذاری فایل
                        </Label>

                        <FormGroup className="row">
                          <Label className="col-sm-3 col-form-label">
                            عکس های کتاب
                          </Label>

                          <Row>
                            {dataImages.map((item, index) => {
                              return (
                                <Alert
                                  key={index}
                                  className="alert-dismissible m-1"
                                  color={"info dark"}
                                  isOpen={true}
                                  target={"Aler"}
                                >
                                  <img
                                    width="100px"
                                    height="100px"
                                    src={`${ServerUrl}/${item}`}
                                  />
                                  <span
                                    className="float-right btn"
                                    onClick={() => {
                                      setDataImages(
                                        dataImages.filter((i) => i !== item)
                                      );
                                      setremoveImages([...removeImages, item]);
                                    }}
                                  >
                                    <Trash color="red" />
                                  </span>
                                </Alert>
                              );
                            })}
                          </Row>
                          <Col sm="12">
                            <ImageUploader
                              withIcon={true}
                              withPreview={true}
                              label="حداکثر حجم عکس 1 مگابایت | فرمت عکس ها jpg - png - jpeg"
                              fileTypeError="فرمت عکس اشتباه است"
                              fileSizeError="حجم عکس بیشتر از حد مجاز است"
                              buttonText="عکس را انتخاب کنید"
                              onChange={(e) => setImages(e)}
                              imgExtension={[
                                ".jpg",
                                ".gif",
                                ".png",
                                ".gif",
                                ".jpeg",
                              ]}
                              maxFileSize={5242880}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup className="row">
                          <Label className="col-sm-3 col-form-label">
                            فایل pdf
                          </Label>
                          <Col sm="12" className="p-5">
                            {dataFiles.map((item, index) => {
                              return (
                                <Alert
                                  key={index}
                                  className="alert-dismissible"
                                  color={"info dark"}
                                  isOpen={true}
                                  target={"Aler"}
                                >
                                  <a
                                    href={`${ServerUrl}/${item.path}`}
                                    download={true}
                                  >
                                    <strong>{item.name} </strong>
                                  </a>

                                  <span
                                    className="float-right btn"
                                    onClick={() => {
                                      setDataFiles(
                                        dataFiles.filter(
                                          (i) => i.path !== item.path
                                        )
                                      );
                                      setRemoveFiles([
                                        ...removeFiles,
                                        item.path,
                                      ]);
                                    }}
                                  >
                                    <Trash color="red" />
                                  </span>
                                </Alert>
                              );
                            })}
                            <Input
                              className="form-control"
                              multiple
                              name="file"
                              type="file"
                              accept=".pdf,.txt,.doc"
                              onChange={(e) => setFiles(e.target.files)}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup className="row">
                          <Label className="col-sm-3 col-form-label">
                            فایل صوتی
                          </Label>
                          <Col sm="12" className="p-5">
                            {dataAudio.map((item, index) => {
                              return (
                                <Alert
                                  key={index}
                                  className="alert-dismissible"
                                  color={"info dark"}
                                  isOpen={true}
                                  target={"Aler"}
                                >
                                  <audio
                                    controls
                                    style={{
                                      with: "100px",
                                      maxHeight: "100%",
                                      maxWidth: "100%",
                                      margin: "auto",
                                      objectFit: "contain",
                                    }}
                                  >
                                    <source src={`${ServerUrl}/${item.path}`} />
                                  </audio>
                                  <span
                                    className="float-right btn top-0"
                                    onClick={() => {
                                      setDataAudio(
                                        dataAudio.filter(
                                          (i) => i.path !== item.path
                                        )
                                      );
                                      setRemoveAudio([
                                        ...removeAudio,
                                        item.path,
                                      ]);
                                    }}
                                  >
                                    <Trash color="red" />
                                  </span>
                                </Alert>
                              );
                            })}
                            <Input
                              className="form-control"
                              multiple
                              onChange={(e) => setAudio(e.target.files)}
                              name="audio"
                              accept=".mp3,.wav,.wma,.aac,.m4a,.flac"
                              type="file"
                            />
                          </Col>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Card>
                </CardBody>
                <CardFooter>
                  <BtnLoader show={isSubmitBtnLoader} />
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
      </Form>
    </Formik>
  );
};

export default EditBook;
