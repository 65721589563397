import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  Label,
  Button,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
} from "reactstrap";
import swal from "sweetalert";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { postApi } from "../../../service/api";
const Register = (props) => {
  const [togglePassword, setTogglePassword] = useState(false);
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("user");

  const [selected, setSelected] = useState("firebase");

  const handleChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const body = {
        ...values,
        role,
        generateBy: "admin",
        studentId: String(values.studentId),
        mobile: String(values.mobile),
      };

      await postApi({
        path: "/auth/register",
        body,
      });
      resetForm({});
      swal({
        title: "موفق",
        icon: "success",
      });
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <Formik
        initialValues={{
          name: "",
          password: "",
          email: "",
          studentId: "",
          generateBy: "admin",
          role: "student",
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required(
            <span style={{ color: "red", padding: "2px" }}>نام الزامیست</span>
          ),
          password: Yup.string()
            .min(
              6,
              <span style={{ color: "red", padding: "2px" }}>
                رمز عبور حداقل 6 کاراکتر باید باشد
              </span>
            )

            .required(
              <span style={{ color: "red", padding: "2px" }}>
                رمز عبور الزامیست
              </span>
            ),
          email: Yup.string().email(
            <span style={{ color: "red", padding: "2px" }}>
              فرمت ایمیل اشتباه است
            </span>
          ),
          studentId: Yup.string().required(
            <span style={{ color: "red", padding: "2px" }}>
              آی دی محصل الزامیست
            </span>
          ),
          mobile: Yup.string(
            <span style={{ color: "red", padding: "2px" }}>
              موبایل اشتباه است
            </span>
          ).required(
            <span style={{ color: "red", padding: "2px" }}>
              آی دی محصل الزامیست
            </span>
          ),
        })}
        onSubmit={handleSubmit}
      >
        <Form>
          <Container fluid={true} className="p-0">
            <Row className="m-0">
              <Col xs="12" className="p-0 dark">
                <div className="login-card">
                  <div>
                    <div>
                      <a className="logo" href="#javascript">
                        <img
                          className="img-fluid for-light"
                          src={require("../../../assets/images/logo/login.png")}
                          alt=""
                        />
                        <img
                          className="img-fluid for-dark"
                          src={require("../../../assets/images/logo/logo_dark.png")}
                          alt=""
                        />
                      </a>
                    </div>
                    <div className="login-main">
                      <TabContent
                        activeTab={selected}
                        className="content-login"
                      >
                        <TabPane
                          className="fade show"
                          tabId={selected === "firebase" ? "firebase" : "jwt"}
                        >
                          <div
                            className="theme-form"
                            style={{ textAlign: "right", direction: "rtl" }}
                          >
                            <h4>{"ثبت کاربر"}</h4>
                            <FormGroup>
                              <Label htmlFor="exampleFormControlInput1">
                                نام
                              </Label>
                              <Field
                                className="form-control"
                                name="name"
                                type="text"
                              />
                              <ErrorMessage name="name" />
                            </FormGroup>
                            <FormGroup>
                              <Label htmlFor="exampleFormControlInput1">
                                موبایل
                              </Label>
                              <Field
                                className="form-control"
                                name="mobile"
                                type="text"
                              />
                              <ErrorMessage name="mobile" />
                            </FormGroup>
                            <FormGroup>
                              <Label htmlFor="exampleFormControlInput1">
                                ایمیل
                              </Label>
                              <Field
                                className="form-control"
                                name="email"
                                type="text"
                              />
                              <ErrorMessage name="email" />
                            </FormGroup>
                            <FormGroup>
                              <Label htmlFor="exampleFormControlInput1">
                                آی دی محصل
                              </Label>
                              <Field
                                className="form-control"
                                name="studentId"
                                type="number"
                              />
                              <ErrorMessage name="studentId" />
                            </FormGroup>
                            <FormGroup>
                              <Label>نمبر کارت</Label>
                              <Field
                                className="form-control"
                                name="nombrCard"
                                type="text"
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label htmlFor="exampleFormControlInput1">
                                دانشکده
                              </Label>
                              <Field
                                className="form-control"
                                name="college"
                                type="text"
                              />
                              <ErrorMessage name="email" />
                            </FormGroup>
                            <FormGroup>
                              <Label htmlFor="exampleFormControlSelect3">
                                وظیفه
                              </Label>
                              <Input
                                type="select"
                                name="role"
                                onChange={(e) => {
                                  setRole(e.target.value);
                                }}
                                className="form-control digits"
                              >
                                <option value={"user"}>{"کاربر"}</option>
                                <option value={"student"}>{"محصل"}</option>
                                <option value={"employee"}>{"کارمند"}</option>
                                <option value={"teacher"}>{"استاد"}</option>
                              </Input>
                            </FormGroup>

                            <FormGroup>
                              <Label htmlFor="exampleFormControlInput1">
                                رمز عبور
                              </Label>
                              <Field
                                className="form-control"
                                name="password"
                                type="text"
                              />
                              <ErrorMessage name="password" />
                            </FormGroup>
                            <div className="form-group mb-0 mt-4">
                              <Button
                                color="primary"
                                type="submit"
                                className="btn-block"
                              >
                                ثبت
                              </Button>
                            </div>
                          </div>
                        </TabPane>
                      </TabContent>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </Form>
      </Formik>
    </>
  );
};

export default Register;
