// dashbaord
import ShowAllData from "../continers/showAllData";
import CreateNewData from "../continers/createNewData";
import EditeData from "../continers/editeData/index";
import Dashboard from "../components/admin/dashboard"; 
import {
  BooksBaseName,
  CategoriesBaseName,
  LoanBaseName,
  CommentBaseName,
  UserBaseName,
  AuthBaseName,
  SliderBaseName,
  NotificationsBaseName,
  BannerBaseName,
} from "../constant/index";

export const routes = [
  /////////////////////////// Admin Route /////////////////////////////

  {
    path: `${process.env.PUBLIC_URL}`,
    Component: Dashboard,
  },
   
  //users
  
  //Comments  
  //slider
   

  //grouping
  ,
  //loan
   
  //Banner
   
  //auth
  {
    path: `${process.env.PUBLIC_URL}/admin/insert-${UserBaseName}`,
    baseName: AuthBaseName,
    Component: CreateNewData,
  },
];
