import React, { Fragment, useState, useCallback, useMemo } from "react";
// import Breadcrumb from '../../layout/breadcrumb'
import differenceBy from "lodash/differenceBy";
import Breadcrumb from "../../../layout/breadcrumb/index";
import Jalali from "moment-jalali";
import { statusTypes } from "../../../constant/index";
import DataTable from "react-data-table-component";
// import {tableData} from '../../data/dummyTableData'
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Label,
  FormGroup,
  Input,
} from "reactstrap";
import { Eye } from "react-feather";
import { UserDomainUrl } from "../../../config/index.json";

import Paginate from "../../paginate";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import swal from "sweetalert";

export default function Comments(props) {
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [showFilter, setShowFilter] = useState(false);

  const tableColumns = [
    {
      name: "عنوان",
      selector: "title",
      center: true,
    },
    {
      name: "کاربر",
      selector: (row) => row.user?.name,
      center: true,
    },
    {
      name: "وضعیت",
      selector: (row) => {
        return (
          <>
            {row.status === "active" ? (
              <span className="text-success">فعال</span>
            ) : null}
            {row.status === "inactive" ? (
              <span className="text-danger">غیرفعال</span>
            ) : null}
            {row.status === "pending" ? <span>نامشخص</span> : null}
          </>
        );
      },
    },
    {
      name: "تاریخ ثبت",
      selector: (row) => {
        return Jalali(row.createdAt, "YYYY-M-D HH:mm:ss").format("jYYYY/jM/jD");
      },
    },
    {
      name: "نمایش",
      cell: (row) => {
        const parseTitle = row.title.split(" ").join("-");
        return (
          <>
            <a
              href={`${UserDomainUrl}/single-articel/${parseTitle}?id=${row._id}`}
              target={"_blank"}
            >
              <Eye className="text-info" style={{ cursor: "pointer" }} />
            </a>
          </>
        );
      },
    },
    {
      name: "تغییر وضعیت",
      allowOverflow: true,
      button: true,
      cell: (row) => {
        return (
          <div
            onClick={() =>
              props.handleEditOneFeild({
                path: `${props.baseName}/${row._id}`,
                body: {
                  status: row.status === "active" ? "inactive" : "active",
                },
              })
            }
          >
            {row.status === "inactive" || row.status === "pending" ? (
              <button className="btn btn-primary p-1" raised primary>
                فعال شود
              </button>
            ) : (
              <button className="btn btn-primary p-1" raised primary>
                غیرفعال شود
              </button>
            )}
          </div>
        );
      },
    },
  ];

  const handleRowSelected = React.useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const contextActions = React.useMemo(() => {
    return (
      <button
        key="delete"
        onClick={() => alert()}
        style={{ backgroundColor: "red" }}
        icon
      >
        Delete
      </button>
    );
  }, [props.data?.docs, selectedRows, toggleCleared]);
  console.log(props.data);

  return (
    <Fragment>
      <Breadcrumb parent="مقالات" title="نمایش همه " />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>{"نمایش تمامی مقاله ها"}</h5>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor="exampleFormControlSelect3">وضعیت</Label>
                      <Input
                        type="select"
                        name="type"
                        onChange={(e) =>
                          props.handleFilter({
                            filter: { status: e.target.value, page: 1 },
                          })
                        }
                        className="form-control digits"
                      >
                        <option value={""}>{"همه"}</option>
                        <option value={"active"}>{"فعال"}</option>
                        <option value={"inactive"}>{"غیرفعال"}</option>
                        <option value={"pending"}>{"درانتظار تایید"}</option>
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>

                <DataTable
                  data={props.data?.docs}
                  columns={tableColumns}
                  striped={true}
                  center={true}
                  noDataComponent="موردی یافت نشد"
                  persistTableHead
                  contextActions={contextActions}
                  onSelectedRowsChange={handleRowSelected}
                  clearSelectedRows={toggleCleared}
                />
                <Paginate
                  pages={props.data?.pages}
                  page={props.data?.page}
                  total={props.data?.total}
                  changePage={(e) =>
                    props.getData({
                      params: { page: e },
                    })
                  }
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}
